import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/index";
import thunk from "redux-thunk"

const UserInfoFromStorage = localStorage.getItem('UserInfo') ?
  JSON.parse(localStorage.getItem('UserInfo')) :
  null

const AdminInfoFormStorage = localStorage.getItem('AdminInfo') ?
  JSON.parse(localStorage.getItem('AdminInfo')) :
  null

const initialState = {
  userLogin: {
    UserInfo: UserInfoFromStorage,
    
  },
  adminLogin: {
    AdminInfo: AdminInfoFormStorage,
  },
  userRegister:{
    RegUserInfo: UserInfoFromStorage,
  },
  lyrics: {
    line: 0,
    lyrics: []
  },
}

export const store = createStore(
  reducers,
  initialState,
  applyMiddleware(thunk)
)
/*  export default store */