import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Spinner } from 'react-bootstrap'

const AdminSettings = () => {
    const [siteName, setSiteName] = useState("")
    const [metaTitle, setMetaTitle] = useState("")
    const [metaDesc, setMetaDesc] = useState("")
    const [metaKey, setMetaKey] = useState("")
    const [copyRights, setCopyRights] = useState("")
    const [appVersionIOS, setAppVersionIOS] = useState("1.0.0")
    const [appVersionAndroid, setAppVersionAndroid] = useState("1.0.0")
    const [starSinger, setStarSinger] = useState("")
    const [favIconPreview, setFavIconPreview] = useState(null)
    const [favIconErr, setFavIconErr] = useState("")
    const [favIcon, setFavIcon] = useState(null)

    const [darkfavIconPreview, setDarkFavIconPreview] = useState(null)
    const [darkfavIconErr, setDarkFavIconErr] = useState("")
    const [darkfavIcon, setDarkFavIcon] = useState(null)

    const [dataLoad, setDataLoad] = useState(false)
   
    const [logoPreview, setLogoPreview] = useState(null)
    const [logoErr, setLogoErr] = useState("")
    const [logo, setLogo] = useState(null)
    const [settingList, setSettingList] = useState([])
    const [serverUrl, setServerUrl] = useState("")
    const [logoUrl, setLogoUrl] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [settingsId, setSettingsId] = useState("")


    useEffect(() => {
        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        /*  const config = {
             headers: {
                 'Content-Type': 'application/json',
             },
         } */
        axios.post(
            baseUrl + 'api/web/getadminsettings',
            {

            },
            config
        ).then(res => {
            if (res.data.adminSetting != null) {
                console.log(res.data.adminSetting)
                console.log(res.data)
                setSettingList(res.data.adminSetting)
                setSiteName(res.data.adminSetting.siteName)
                setMetaTitle(res.data.adminSetting.metaTitle)
                setMetaDesc(res.data.adminSetting.metaDesc)
                setMetaKey(res.data.adminSetting.metaKey)
                setCopyRights(res.data.adminSetting.copyRights)

                setAppVersionAndroid(res.data.adminSetting.appVersionAndroid)
                setAppVersionIOS(res.data.adminSetting.appVersionIOS)

                setServerUrl(res.data.serverUrl)
                setLogoUrl(res.data.logoUrl)
                setSettingsId(res.data.adminSetting._id)
                setFavIconPreview(res.data.serverUrl + res.data.logoUrl + "/" + res.data.adminSetting.favIcon)
                setDarkFavIconPreview(res.data.serverUrl + res.data.logoUrl + "/" + res.data.adminSetting.darkfavIcon)
                setLogoPreview(res.data.serverUrl + res.data.logoUrl + "/" + res.data.adminSetting.logo)
                setIsLoading(false)

            }

        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })

    }, [dataLoad]);
    const onDrop1 = useCallback(acceptedFiles => {

        const f = acceptedFiles[0];

        if (f != null && f !== "undefined") {
            console.log(f);
            setFavIconErr("")
            setFavIcon(f)
            setFavIconPreview(URL.createObjectURL(f))

        }
        else {
            setFavIcon(null)
            setFavIconErr("File not supported")
        }

    }, [])
    const onDrop2 = useCallback(acceptedFiles => {

        const f = acceptedFiles[0];

        if (f != null && f !== "undefined") {
            console.log(f);
            setDarkFavIconErr("")
            setDarkFavIcon(f)
            setDarkFavIconPreview(URL.createObjectURL(f))

        }
        else {
            setDarkFavIcon(null)
            setDarkFavIconErr("File not supported")
        }

    }, [])
    const onDrop = useCallback(acceptedFiles => {

        const f = acceptedFiles[0];

        if (f != null && f !== "undefined") {
            console.log(f);
            setLogoErr("")
            setLogo(f)
            setLogoPreview(URL.createObjectURL(f))

        }
        else {
            setLogo(null)
            setLogoErr("File not supported")
        }

    }, [])
    const { getRootProps: getRootFavIconprops, getInputProps: getInputFavIconsProps } = useDropzone({ onDrop: onDrop1, multiple: false, accept: 'image/png' })
    const { getRootProps: getRootDarkFavIconprops, getInputProps: getInputDarkFavIconsProps } = useDropzone({ onDrop: onDrop2, multiple: false, accept: 'image/png' })
    const { getRootProps: getRootLogoprops, getInputProps: getInputLogoProps } = useDropzone({ onDrop: onDrop, multiple: false, accept: 'image/png' })





    return (
        <div>
            {isLoading ?
                <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                    <Spinner animation="grow" />
                </div>
                :

                <div className="col  vh-100">
                    <div className="container h-100 mt-5">
                        <h1 className='f-semibold'>Admin Settings</h1>
                        <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xxl-12 col-xl-12 bg-white admin-setting-base shadow-sm mt-4 p-5'>
                            <form>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xxl-12 col-xl-12 admin-setting-form-section'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'  {...getRootLogoprops()}>
                                            <label className="f-semibold w-100 mb-2">Logo Upload</label>

                                            <input type="file" className="form-control cursor-pointer" /* id="upload-logo" */  {...getInputLogoProps({ className: 'dropzone' })} />
                                            <label htmlFor="upload-logo" className="IconUpload cursor-pointer">
                                                <img src={(logoPreview == null || logoPreview == " ") ? "../images/music.png" : logoPreview} alt="Upload" /></label>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6' {...getRootFavIconprops()}>
                                            <label className="f-semibold w-100 mb-2">To Light mode Fav Icon Upload</label>
                                            <input type="file" className="form-control Cursor-pointer" /* id="upload-favicon"   */{...getInputFavIconsProps({ className: 'dropzone' })} />
                                            <label htmlFor="upload-favicon" className="IconUpload cursor-pointer">
                                                <img src={(favIconPreview == null || favIconPreview == " ") ? "../images/music.png" : favIconPreview} alt="Upload" /></label>
                                        </div>
                                         <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6' {...getRootDarkFavIconprops()}>
                                            <label className="f-semibold w-100 mb-2">To Dark mode Fav Icon Upload</label>
                                            <input type="file" className="form-control Cursor-pointer" {...getInputDarkFavIconsProps({ className: 'dropzone' })} />
                                            <label htmlFor="upload-favicon" className="IconUpload cursor-pointer">
                                                <img src={(darkfavIconPreview == null || darkfavIconPreview == " ") ? "../images/music.png" : darkfavIconPreview} alt="Upload" /></label>
                                        </div> 
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                            <label className="f-semibold mb-2">Site Name</label>
                                            <input type="text" className="form-control" value={siteName} onChange={(e) => setSiteName(e.target.value)} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                            <label className="f-semibold mb-2">Meta Title</label>
                                            <input type="text" className="form-control" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                            <label className="f-semibold mb-2">Meta Description</label>
                                            <textarea className='form-control' value={metaDesc} onChange={(e) => setMetaDesc(e.target.value)}></textarea>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                            <label className="f-semibold mb-2">Meta Keywords</label>
                                            <input type="text" className="form-control" value={metaKey} onChange={(e) => setMetaKey(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                            <label className="f-semibold mb-2">App Version Android</label>
                                            <input type="text" className="form-control" value={appVersionAndroid} onChange={(e) => setAppVersionAndroid(e.target.value)} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                            <label className="f-semibold mb-2">App Version IOS</label>
                                            <input type="text" className="form-control" value={appVersionIOS} onChange={(e) => setAppVersionIOS(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                            <label className="f-semibold mb-2">Copyrights Description</label>
                                            <input type="text" className="form-control" value={copyRights} onChange={(e) => setCopyRights(e.target.value)} />
                                        </div>
                                    </div>
                                  
                                    <div className='row mt-3'>
                                        <div className='col-lg-12 col-md-21 col-sm-12 col-md-12 col-xxl-12 col-xl-12'>
                                            <button className="adminThemebtn" onClick={(e) => {
                                                e.preventDefault();
                                                setIsLoading(true)
                                                if ((favIcon !== null || logo != null) && (siteName != "" && metaTitle != "" && metaDesc != "" && metaKey != "" && copyRights != "")) {


                                                    var formData = new FormData()
                                                    formData.append("settingsId", settingsId)
                                                    formData.append("siteName", siteName)
                                                    formData.append("metaTitle", metaTitle)
                                                    formData.append("metaDesc", metaDesc)
                                                    formData.append("metaKey", metaKey)
                                                    formData.append("copyRights", copyRights)
                                                    formData.append("appVersionAndroid", appVersionAndroid)
                                                    formData.append("appVersionIOS", appVersionIOS)
                                                    if (favIcon != null)
                                                        formData.append("file", favIcon)
                                                    if (darkfavIcon != null)
                                                        formData.append("darkfile", darkfavIcon)
                                                    if (logo != null)
                                                        formData.append("logo", logo)

                                                    console.log(formData);

                                                    var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                                    var t1 = local['token']

                                                    const config = {
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            "Authorization": `Bearer ${t1}`,
                                                        },
                                                    }
                                                    axios.post(baseUrl + "api/web/setadminsettingswithimg", formData,config).then(res => {

                                                        console.log(res.data)
                                                        if (res.data.status == 1) {

                                                            setDataLoad(!dataLoad)


                                                        }
                                                        else {
                                                            setLogoErr("Failed to upload")
                                                            setIsLoading(false)
                                                            setFavIconErr("Failed to upload")
                                                        }
                                                    }).catch(err => {

                                                        setLogoErr("Failed to upload")
                                                        setFavIconErr("Failed to upload")
                                                        setIsLoading(false)

                                                    })



                                                }
                                                else if (favIcon == null && logo == null && siteName != "" && metaTitle != "" && metaDesc != "" && metaKey != "" && copyRights != ""&&appVersionIOS!=""&&appVersionAndroid!="") {

                                                    var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                                    var t1 = local['token']

                                                    const config = {
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            "Authorization": `Bearer ${t1}`,
                                                        },
                                                    }

                                                    axios.post(baseUrl + "api/web/setadminsettingswithoutimg", { siteName, metaTitle, metaDesc, metaKey, copyRights, settingsId,appVersionAndroid,appVersionIOS }, config).then(res => {

                                                        console.log(res.data)
                                                        if (res.data.status == 1) {
                                                            setDataLoad(!dataLoad)
                                                        }
                                                        else {

                                                        }
                                                    }).catch(err => {
                                                        setFavIconErr("Failed to upload")
                                                        setLogoErr("Failed to upload")
                                                        setIsLoading(false)
                                                        setDataLoad(!dataLoad)
                                                    })
                                                }

                                                else {
                                                    console.log("error");
                                                    setIsLoading(false)
                                                    setFavIconErr("fill the manditory field")
                                                    setLogoErr("Failed to upload")
                                                }

                                            }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            }
        </div>
    )
}

export default AdminSettings
