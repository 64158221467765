
import React, { useState, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import AP from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const AudioPlayer = ({ songSrc, audioRef, onLoadedData, onListen ,onSeeked}) => {
    return (
        <div className="audioContainerBase ">
            <AP
            loop={false}
                autoPlay={false}
                listenInterval={20}
                onSeeked={onSeeked}
               
                onLoadedData={onLoadedData}
                onListen={onListen}
                ref={audioRef}
                src={songSrc}
                showJumpControls={false}
                onPlay={e => console.log(e)}
            />
        </div>
    )
}
export default AudioPlayer