import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Spinner } from 'react-bootstrap'

const AppFeatureSetttings = () => {
    const [starSingerCount, setStarSingerCount] = useState("")
    const [goldCoins, setGoldCoins] = useState("")
    const [silverCoins, setSilverCoins] = useState("")
    const [bronzeCoins, setBronzeCoins] = useState("")
    const [totalVotes, setTotalVotes] = useState("")
    const [userReportList, setUserReportList] = useState([])
    const [privilegeList, setPrivilegeList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [settingsId, setSettingsId] = useState("")
    const [settingList, setSettingList] = useState([])
    const [dataLoad, setDataLoad] = useState(false)


    useEffect(() => {
        setIsLoading(true)
        var local=JSON.parse(localStorage.getItem("AdminInfo"))
                                   
        var t1=local['token']
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }

   /*      const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        } */
        axios.post(
            baseUrl +'api/web/getappfeaturesettings',
            {

            },
            config
        ).then(res => {
            if (res.data.adminSetting != null) {
             
             setSettingList(res.data.adminSetting)
             setSettingsId(res.data.adminSetting._id)
             setStarSingerCount(res.data.adminSetting.starSingerCount)
             setGoldCoins(res.data.adminSetting.goldCoins)
             setSilverCoins(res.data.adminSetting.silverCoins)
             setBronzeCoins(res.data.adminSetting.bronzeCoins)
             setTotalVotes(res.data.adminSetting.totalVotes)
             setUserReportList(res.data.adminSetting.userReportList.join(","))
             setPrivilegeList(res.data.adminSetting.privilege.join(","))
             setIsLoading(false)
           
            }

        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })

    }, [dataLoad]); 
    
    



    return (
        <div>
        {isLoading ?
            <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                <Spinner animation="grow" />
            </div>
            :
            
        <div className="col  vh-100">
            <div className="container h-100 mt-5">
                <h1 className='f-semibold'>App Feature Settings</h1>
                <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xxl-12 col-xl-12 bg-white admin-setting-base shadow-sm mt-4 p-5'>
                    <form>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xxl-12 col-xl-12 admin-setting-form-section'>
                            <div className='row mt-3'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                    <label className="f-semibold mb-2">Star Singer Count</label>
                                    <input type="text" className="form-control" value={starSingerCount} onChange={(e) => setStarSingerCount(e.target.value)} />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                    <label className="f-semibold mb-2">Gift Coins for Gold</label>
                                    <input type="text" className="form-control" value={goldCoins} onChange={(e) => setGoldCoins(e.target.value)} />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                
                                <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                    <label className="f-semibold mb-2">Gift coins for silver</label>
                                    <input type="text" className="form-control" value={silverCoins} onChange={(e) => setSilverCoins(e.target.value)} />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                    <label className="f-semibold mb-2">Gift coins for Bronze </label>
                                    <input type="text" className="form-control" value={bronzeCoins} onChange={(e) => setBronzeCoins(e.target.value)} />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                    <label className="f-semibold mb-2">Report List</label>
                                    <input type="text" className="form-control" value={userReportList} onChange={(e) => setUserReportList(e.target.value)} />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                    <label className="f-semibold mb-2">Total votes</label>
                                    <input type="text" className="form-control" value={totalVotes} onChange={(e) => setTotalVotes(e.target.value)} />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'>
                                    <label className="f-semibold mb-2">Privilege List</label>
                                    <input type="text" className="form-control" value={privilegeList} onChange={(e) => setPrivilegeList(e.target.value)} />
                                </div>
                               
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-12 col-md-21 col-sm-12 col-md-12 col-xxl-12 col-xl-12'>
                                <button className="adminThemebtn" onClick={(e) => {
                                e.preventDefault();

                                 if ( starSingerCount != "" && goldCoins != "" && silverCoins != "" && bronzeCoins != "" && userReportList != "" && settingsId != "" && totalVotes != "" && privilegeList !="" ) {
                                    var local=JSON.parse(localStorage.getItem("AdminInfo"))
                                   
                                    var t1=local['token']
                                  
                                    const config = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            "Authorization": `Bearer ${t1}`,
                                        },
                                    }

                                   /*  const config = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                    } */
                                     var userReports = userReportList.split(",")
                                     var privileges = privilegeList.split(",")
                                    axios.post(baseUrl +"api/web/setappfeaturesettings", { starSingerCount, goldCoins, silverCoins,bronzeCoins,userReports,settingsId,totalVotes,privileges }, config).then(res => {

                                        console.log(res.data)
                                        if (res.data.status == 1) {
                                          setDataLoad(!dataLoad)
                                        }
                                        else {

                                        }
                                    }).catch(err => {
                                      
                                    })
                                }

                                else {
                                    console.log("error");
                                    
                                }

                            }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </div>
        }
        </div>
    )
}

export default AppFeatureSetttings
