import React from 'react'
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { matchSorter } from 'match-sorter'


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (

    <input
      value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`search`}
      className="input-control shadow-sm w-100 m-0"
      style={{
        fontSize: '1.1rem',
        border: '0',
      }}
    />
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}

    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

//custom column filter

 function onFilteredChangeCustom (value, accessor) {
  let filtered = this.state.filtered;
  let insertNewFilter = 1;

  if (filtered.length) {
    filtered.forEach((filter, i) => {
      if (filter["id"] === accessor) {
        if (value === "" || !value.length) filtered.splice(i, 1);
        else filter["value"] = value;

        insertNewFilter = 0;
      }
    });
  }

  if (insertNewFilter) {
    filtered.push({ id: accessor, value: value });
  }

  this.setState({ filtered: filtered });
};



function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val
function DataTable({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {

    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    usePagination
  )

  // Render the UI for your table
  return (
    <>

      <div className="table-responsive adminTable">
        <div className="row d-flex justify-content-between m-0">
          <div className="col-12 col-md-3 col-sm-3 col-lg-2 col-xxl-2 col-xl-2 p-0">
            <select className="select-control shadow-sm"
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-3 col-sm-3 col-lg-3 col-xxl-3 col-xl-3 p-0">
           
            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xxl-12 col-xl-12 p-0">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>

          </div>
        </div>


        <table {...getTableProps()} class="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="shadow-sm">
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}

      <nav className="my-5 d-inline-block  pagination">
        <ul class="pagination mb-0 shadow-sm">
          <li class="page-item ">
            <a class="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage} >{'<<'}</a>
          </li>
          <li class="page-item">
            <a class="page-link" onClick={() => previousPage()} disabled={!canPreviousPage} >{'<'}</a></li>
          <li class="page-item"><a class="page-link" onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</a></li>
          <li class="page-item">
            <a class="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</a>
          </li>
        </ul>
        <span className="mt-2 d-inline-block">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
      </nav>

    </>
  )
}
export default DataTable;
export {
  SelectColumnFilter
}