import React, {useState} from 'react'
import LoginModal from './LoginModal'

const Footer = props => {
    
    return (
       <> <div className="footerBase pt-5">
           <div className='container'>
           <div className='row'>
           <div className="col-12 col-xxl-6 col-xl-6 col-lg-6 col-sm-6 col-md-6">
                <ul className="list-unstyled">
                    <li className='mb-4'><a href="/privacy-policy" className='text-light text-decoration-none'>Privacy policy</a></li>
                    <li className='mb-4'><a href="/terms-of-use" className='text-light text-decoration-none'>Terms and Conditions</a></li>
                </ul>
            </div>
            <div className="col-12 col-xxl-6 col-xl-6 col-lg-6 col-sm-6 col-md-6 text-end">
                <h2 className="mb-3">Download the App Now!</h2>
                <ul className="list-inline downloadUl">
                    <li className="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.yoyosinger.yoyo"><img src={`${process.env.PUBLIC_URL}/images/google.png`} alt="Play Store" /></a></li>
                    <li className="list-inline-item"><a href="#"><img src={`${process.env.PUBLIC_URL}/images/app.png`} alt="App Store" /></a></li>
                </ul>
            </div>
           </div>
           </div>
          
            
            <div>
                
                </div>
               
          <div className='text-center mt-5'>
                <p className='text-light '>Copyrights <span>&#169;</span> YOYO SINGER PRIVATE LIMITED</p>
                <p className='text-light pb-3'>Design and Developed @ <a className='text-light text-decoration-none' href='https://www.pictuscode.com/'>Pictuscode PVT LTD</a></p>
          </div>
        </div>
         <LoginModal show={props.show} handleClose={()=>props.handleClose()}  />
    
         
         </>
        
    )
}

export default Footer
