import {

  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
  

} from '../constants/UserConstant'

export const AdminLoginReducer = (state = {}, action) => {

  switch (action.type) {
    case ADMIN_LOGIN_REQUEST:

      return {
        loading: true
      }
    case ADMIN_LOGIN_SUCCESS:
      return {
        loading: false, AdminInfo: action.payload
      }
    case ADMIN_LOGIN_FAIL:
      return {
        loading: false, error: action.payload
      }

    case ADMIN_LOGOUT:
      return {}
    default:
      return state
  }
}