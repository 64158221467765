
/* const initialState =[0,1];

const reducer = (
    state = initialState,
    action
) => {
    
    switch (action.type) {
        case SET_LYRICS:
            
            state[0] = action.payload
            console.log(state[0]);
            state[1]=Number(state[1])+1;
            return state
        
        default:
            return state
    }
} 
export default LyricsReducer */

import {
    SET_LYRICS,
    SET_CURRENT_LINE_AT


} from '../constants/UserConstant'

export const LyricsReducer = (state = {}, action) => {

    switch (action.type) {
        case SET_LYRICS:
         return {
                line: action.payload.line,
                lyrics: action.payload.lyrics
            }
        case SET_CURRENT_LINE_AT:
                return {
                       line: action.payload.line,
                       lyrics: action.payload.lyrics
                   }
        default:
            return state
    }
}