import React, { useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Spinner } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import { baseUrl } from '../../config/constant';
import useState from 'react-usestateref'
const AdminAccessUserList = () => {

    const [adminUserId, setAdminUserId] = useState(null)
    const [adminName, setAdminName] = useState("")
    const [emailId, setEmailId] = useState("")
    const [password, setPassword] = useState("")
    const [privilege, setPrivilege] = useState("")
    const [privileges, setPrivileges, useRefPrivileges] = useState([])
    const [selectedPrivileges, setSelectedPrivileges, useRefSelectedPrivileges] = useState([])
    const [adminType, setAdminType,useRefAdminType] = useState("Sub Admin")
    const [adminSettings, setAdminSettings] = useState("")
    const [dataLoad, setDataLoad] = useState(false)
    const [tableData, setTableData] = useState([])
    const [deleteId, setDeleteId] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [show, setShow] = useState(false);
    const [tabelData, settabelData] = useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        axios.post(
            baseUrl + 'api/web/getalladminuser',
            {

            },
            config
        ).then(res => {


            if (res.data.admins != null) {
                setAdminSettings(res.data.adminSettings)
                var data = [];
                res.data.admins.map((listValue, index) => {
                    data.push({
                        col1: index + 1,
                        col2: listValue.name,
                        col3: listValue.email,
                        col4: listValue.privilege,
                        col5: listValue.adminType,
                        col6: <ul className="list-inline m-0">
                            <li className="list-inline-item pe-2 pe-auto" data-toggle="tooltip" data-placement="top" title="Edit"><img src={require("../../images/edit.svg").default} onClick={(e) => {
                                setAdminUserId(listValue._id)
                                setAdminName(listValue.name)
                                setPassword("")
                                setEmailId(listValue.email)
                                setSelectedPrivileges(listValue.privilege)
                                setAdminType(listValue.adminType)
                                setShowForm(true)
                            }} alt="edit" /></li>

                            <li className="list-inline-item"  ><img src={require("../../images/delete.svg").default} alt="delete" onClick={(e) => { setShow(true); setDeleteId(listValue._id) }} /></li>

                        </ul>,


                    });

                });
                settabelData(data)
                setDataLoad(true)

            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }, [dataLoad]);


    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'col2',
            },
            {
                Header: 'Email ID',
                accessor: 'col3',
            },

            {
                Header: 'Privilege',
                accessor: 'col4',
            },
            {
                Header: 'Admin Type',
                accessor: 'col5',
            },
            {
                Header: 'Action',
                accessor: 'col6',
            },

        ],
        [tabelData]
    )

    var data1 = React.useMemo(
        () => tabelData,
        [tabelData]
    )


    const onSelectedOptions = (e) => {
        console.log(e.target.value)
        if (useRefSelectedPrivileges.current.includes(e.target.value)) {

            const selectedPrivilegesSet = new Set([...useRefSelectedPrivileges.current]);
            selectedPrivilegesSet.delete(e.target.value)
            setSelectedPrivileges([...selectedPrivilegesSet]);
        }
        else {
            setSelectedPrivileges([...useRefSelectedPrivileges.current, e.target.value]);

        }

    };

    return (
        <>
            {isLoading ?
                <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                    <Spinner animation="grow" />
                </div> :
                <div className="col  vh-100">
                    <div className="container h-100">

                        {!showForm && <div className="">
                            <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                                <div className="componentsDetailsInner text-center d-flex align-items-center">
                                    <h3 className="f-semibold m-0">Total No of Admins</h3>
                                    <h3 className="f-bold ps-5 m-0">{tabelData.length}</h3>
                                </div>
                                <div className="componentsDetailsInner text-center d-flex align-items-center">
                                    <button className="adminThemebtn" onClick={(e) => {
                                        setShowForm(true)
                                        setSelectedPrivileges([])
                                        setAdminUserId(null)
                                        setAdminName("")
                                        setPassword("")
                                        setEmailId("")

                                        setAdminType("Sub Admin")

                                    }}>Add Admins</button>
                                </div>
                            </div>
                            <div className="table-responsive adminTable ">
                                <DataTable columns={columns} data={data1} />
                            </div>

                        </div>}
                        {showForm && <div className="col  vh-100">
                            <div className="container h-100 mt-5">
                                <div className='col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 bg-white p-4'>
                                    <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                        <label className="text-dark mb-1">Name</label>
                                        <input type="text" className="input-control m-0 w-100" value={adminName} onChange={e => setAdminName(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                        <label className="text-dark mb-1">Email ID</label>
                                        <input type="text" className="input-control m-0 w-100" value={emailId} onChange={e => setEmailId(e.target.value)} />
                                    </div>

                                    {
                                    adminUserId == null && <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                        <label className="text-dark mb-1">Password</label>
                                        <input type="text" className="input-control m-0 w-100" value={password} onChange={e => setPassword(e.target.value)} />
                                    </div>
                                    }

                                    <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                        <label className="text-dark mb-1">Privilege</label>
                                        <ul className='list-inline mt-4'>
                                            {adminSettings.privilege.map((obj, key) => {
                                                return <li className='list-inline-item col-lg-4 col-md-4 col-12 m-0'>
                                                    <div class="normal-custom_check ">
                                                        <label class="control control--checkbox">
                                                            <input type="checkbox" value={obj} checked={useRefSelectedPrivileges.current.includes(obj)} onClick={onSelectedOptions} />{obj}
                                                            <div class="control__indicator"></div>
                                                        </label>
                                                    </div>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                    <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                        <label className="text-dark mb-1">Admin Type</label>
                                        <select className='select-control border'
                                            value={useRefAdminType.current}
                                            
                                            onChange={e => {
                                                alert(e.target.value)
                                                setAdminType(e.target.value)
                                                alert(useRefAdminType.current)
                                                if(e.target.value=="Admin"){
                                                    setSelectedPrivileges(adminSettings.privilege)
                                                }
                                            
                                            }}>

                                            <option value="Sub Admin">Sub Admin</option>
                                            <option value="Admin">Admin</option>
                                        </select>
                                    </div>

                                    <div className='my-5 justify-content-between d-flex '>
                                        <button class="adminThemebtn bg-light  text-dark" onClick={(e) => { setShowForm(false) }}>Cancel</button>
                                        <button className="adminThemebtn" onClick={(e) => {
                                            e.preventDefault();
                                            setIsLoading(true) 
                                            console.log(useRefAdminType.current)
                                            alert(useRefAdminType.current)
                                            var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
                                            axios.post(baseUrl + "api/web/addadminuser", { adminUserId: adminUserId, name: adminName, email: emailId, password: password, privilege: useRefSelectedPrivileges.current, adminType:useRefAdminType.current  }, config).then(res => {

                                                console.log(res.data)
                                                if (res.data.status == 1) {
                                                    setDataLoad(!dataLoad)
                                                    setIsLoading(false)
                                                    setShowForm(false)
                                                }
                                                else {

                                                }
                                            }).catch(err => {

                                            })



                                        }}>Submit</button>

                                    </div>
                                </div>

                              {adminUserId!=null&&  <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 bg-white p-4">
                                    <label className="text-dark mb-1">Password</label>
                                    <input type="text" className="input-control m-0 w-100" value={password} onChange={e => setPassword(e.target.value)} />
                                    <div className='my-5 justify-content-between d-flex '>
                                        <button class="adminThemebtn bg-light  text-dark" onClick={(e) => { setShowForm(false) }}>Cancel</button>
                                        <button className="adminThemebtn" onClick={(e) => {
                                            e.preventDefault();
                                            setIsLoading(true)
                                            var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                            var t1 = local['token']
                                    
                                            const config = {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    "Authorization": `Bearer ${t1}`,
                                                },
                                            }
                                            axios.post(baseUrl + "api/web/updateadminuserfield", { adminUserId: adminUserId, value: password, field: "password" }, config).then(res => {

                                                console.log(res.data)
                                                if (res.data.status == 1) {
                                                    setDataLoad(!dataLoad)
                                                    setIsLoading(false)
                                                    setShowForm(false)
                                                }
                                                else {

                                                }
                                            }).catch(err => {

                                            })
                                        }}>Update</button>

                                    </div>
                                </div>}


                            </div>

                        </div>}

                    </div>
                    <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete the Email Template</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center ">
                                <h2 className="f-bold fs-4">Are you sure want to delete Admin </h2>
                                <Button variant="danger" className="me-3" onClick={(e) => {
                                    e.preventDefault();
                                    var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                    var t1 = local['token']
                            
                                    const config = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            "Authorization": `Bearer ${t1}`,
                                        },
                                    }
                                    axios.post(baseUrl + "api/web/deleteadminuser", { adminUserId: deleteId, }, config).then(res => {

                                        console.log(res.data)
                                        if (res.data.status == 1) {

                                            setShow(false)
                                            setDataLoad(!dataLoad)

                                        }
                                        else {

                                        }
                                    }).catch(err => {

                                    })
                                }}>Delete</Button>
                                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                            </div>

                        </Modal.Body>

                    </Modal>
                </div>}
        </>
    )
}

export default AdminAccessUserList
