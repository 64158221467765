import React, { useCallback, useState, useEffect } from 'react'

import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button } from 'react-bootstrap'
const UserLanding = () => {
    let history = useHistory()
    const userLogin = useSelector((state) => state.userLogin)
    const { UserInfo } = userLogin

    const [loading, setLoading] = useState(false)
    const [serverUrl, setServerUrl] = useState(baseUrl)
    const [songs, setSongs] = useState([])
    const [tabelData, settabelData] = useState([])
    const [dataLoad, setdataLoad] = useState(false)
    const [show, setShow] = useState(false);

    const [deleteId, setDeleteId] = useState("");
    if (UserInfo == null) {
        history.push("/")
    }

    useEffect(() => {

        setLoading(true)

        if (UserInfo == null) {

            setLoading(false)
            history.push("/")
        } else {
            setLoading(true)
            const userId = UserInfo._id
            var local = JSON.parse(localStorage.getItem("UserInfo"))

            var t1 = local['token']

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${t1}`,
                },
            }
            axios.post(
                baseUrl + 'api/web/getusersong',
                {
                    userId,
                },
                config
            ).then(res => {

                if (res.data.songs != null) {
                    setSongs(res.data.songs)
                    setServerUrl(res.data.serverUrl)
                    var data = [];
                    res.data.songs.map((listValue, index) => {
                        data.push({
                            col1: index + 1,
                            /* col2: <div>
                                <div className="row">
                                    <div class="col-3">
                                        <img src={res.data.serverUrl + listValue.coverDest + listValue.coverPic} width="50%" />
                                    </div><div className='col-9'>
                                        <div className='row'>{listValue.songTitle} </div>
                                        <div className="row"> {listValue.songArtist} </div>
                                        <div className="row language-tag"> {listValue.songLanguage} </div>
                                        <li className="list-inline-item"  ><img src={require("../../images/delete.svg").default} alt="delete" onClick={(e) => { setShow(true); setDeleteId(listValue._id) }} /></li>
                                    </div>
                                </div></div>,  */
                            col2: <>
                                <h3>{listValue.songTitle}</h3>
                                <audio
                                onPlay={(e) => {
                                       
                                    var audios = document.getElementsByTagName('audio');
                                    for (var i = 0, len = audios.length; i < len; i++) {
                                        if (audios[i] != e.target) {
                                            audios[i].pause();
                                        }
                                    }
                                }}
                                
                                src={serverUrl + listValue.destination + listValue.fileName} controls controlsList="nodownload" height="30px" />
                            </>,
                            col3:

                                <img class="imageCover" src={res.data.serverUrl + listValue.coverDest + listValue.coverPic} width="100px" height="100px" />

                            ,
                            col4: listValue.songArtist,
                            col5: <img src={require("../../images/delete.svg").default} alt="delete" onClick={(e) => { setShow(true); setDeleteId(listValue._id) }} />,
                            col7: listValue.songTitle,
                            col8: listValue.songArtist,

                        });

                    });
                    settabelData(data)
                    setdataLoad(true)

                }
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })
        }

    }, [dataLoad]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const columns = React.useMemo(
        () => [

            {
                Header: 'Song No ',
                accessor: 'col1', // accessor is the "key" in the data
            },

            {
                Header: 'Song Title',
                accessor: 'col2',
            },

            {
                Header: 'Song Album',
                accessor: 'col3',
            },
            {
                Header: 'Artist Name',
                accessor: 'col4',
            },
            {
                Header: 'Delete',
                accessor: 'col5',
            },
            {
                Header: 'title',
                accessor: 'col7',
            },
            {
                Header: 'artiest',
                accessor: 'col8',
            },

        ],
        [tabelData]
    )

    var data1 = React.useMemo(
        () => tabelData,
        [tabelData]
    )

    return (<>
        {loading ? <Spinner></Spinner> :
            <div className="container">
                <div className="text-center my-5 songUpload">
                    <div className="table-responsive adminTable userSongList stickerCattable mt-5">
                        <DataTable columns={columns} data={data1} />
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete the Song</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center ">
                            <h2 className="f-bold fs-4">Are you sure want to delete the Song </h2>
                            <Button variant="danger" className="me-3" onClick={(e) => {
                                e.preventDefault();
                                var local = JSON.parse(localStorage.getItem("UserInfo"))

                                var t1 = local['token']

                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "Authorization": `Bearer ${t1}`,
                                    },
                                }
                                axios.post(baseUrl + "api/web/deletesong", { deleteId }, config).then(res => {


                                    if (res.data.status == 1) {

                                        setShow(false)
                                        setdataLoad(!dataLoad)

                                    }
                                    else {

                                    }
                                }).catch(err => {

                                })
                            }}>Delete</Button>
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                        </div>

                    </Modal.Body>

                </Modal>
            </div>
        }
    </>
    )
}

export default UserLanding
