import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { baseUrl } from '../../config/constant';
import { Modal, Button, Spinner, Row } from 'react-bootstrap'

const LanguageSettings = () => {
    const [showForm, setShowForm] = useState(false)

    const [dataLoad, setDataLoad] = useState(false)
    const [bannerPreview, setBannerPreview] = useState(null)
    const [bannerErr, setBannerErr] = useState("")
    const [banner, setBanner] = useState(null)
    const [settingList, setSettingList] = useState([])
    const [serverUrl, setServerUrl] = useState("")
    const [bannerUrl, setBannerUrl] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [settingsId, setSettingsId] = useState("")
    const [tabelData, setTabelData] = useState([])
    const [settingData, setSettingData] = useState([])
    const [songLanguage, setSongLanguage] = useState([])
    const [show, setShow] = useState(false)
    const [bannerPicHost, setBannerPicHost] = useState("")
    const [language, setLanguage] = useState("")
    const [deleteId, setDeleteId] = useState("");
    const [altLang, setAltLang] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        /*   const config = {
              headers: {
                  'Content-Type': 'application/json',
              },
          } */
        axios.post(
            baseUrl + 'api/web/getadminsettings',
            {

            },
            config
        ).then(res => {

            if (res.data.adminSetting != null) {
                var data = [];
                setSettingData(res.data.adminSetting)
                setSongLanguage(res.data.adminSetting.songLanguage)

                setSettingsId(res.data.adminSetting._id)
                res.data.adminSetting.songLanguage.map((listValue, index) => {
                    if (index == 0) {
                        setAltLang(listValue)
                    }
                    data.push({
                        col1: index + 1,
                        col2: listValue,
                        col3: <ul className="list-inline m-0">
                            <li className="list-inline-item"  ><img src={require("../../images/delete.svg").default} alt="delete" onClick={(e) => { setShow(true); setLanguage(listValue) }} /></li>
                        </ul>,
                    });
                });
                setTabelData(data)
                setDataLoad(true)
                setIsLoading(false)

            }

        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })

    }, [dataLoad]);




    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Language',
                accessor: 'col2',
            },
            {
                Header: 'Action',
                accessor: 'col3',
            },

        ],
        [tabelData]
    )

    var data1 = React.useMemo(
        () => tabelData,
        [tabelData]
    )

    return (
        <div>
            {isLoading ?
                <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                    <Spinner animation="grow" />
                </div>
                :

                <div className="col  vh-100">

                    <div className="container h-100 mt-5">
                        <h1 className='f-semibold'>Banner Settings</h1>
                        {!showForm && <div className="">
                            <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                                <div className="componentsDetailsInner text-center d-flex align-items-center">
                                    <h3 className="f-semibold m-0">Total No of Language</h3>
                                    <h3 className="f-bold ps-5 m-0">{songLanguage.length}</h3>
                                </div>
                                <div className="componentsDetailsInner text-center d-flex align-items-center">
                                    <button className="adminThemebtn" onClick={(e) => {
                                        setShowForm(true)
                                    }}>Add Language</button>
                                </div>
                            </div>
                            <div className="table-responsive adminTable ">
                                <DataTable columns={columns} data={data1} />
                            </div>

                        </div>}
                        {showForm && <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xxl-12 col-xl-12 bg-white admin-setting-base shadow-sm mt-4 p-5'>
                            <form>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xxl-12 col-xl-12 admin-setting-form-section'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-md-6 col-xxl-6 col-xl-6'  >
                                            <label className="f-semibold w-100 mb-2">Language</label>

                                            <input type="text" className="form-control" value={language} onChange={(e) => setLanguage(e.target.value)} />
                                        </div>
                                    </div>



                                    <div className='row mt-3'>
                                        <div className='col-lg-12 col-md-21 col-sm-12 col-md-12 col-xxl-12 col-xl-12 justify-content-between d-flex'>
                                            <button className="adminThemebtn bg-light  text-dark" onClick={(e) => {
                                                setShowForm(false)
                                            }}>Cancel</button>
                                            <button className="adminThemebtn" onClick={(e) => {
                                                e.preventDefault();
                                                setIsLoading(true)
                                                if (language != null && language.length > 3 && language != '' && language != " ") {
                                                    if (songLanguage.includes(language.toUpperCase())) {
                                                        alert("Language is already existing")

                                                    } else {



                                                        var local = JSON.parse(localStorage.getItem("AdminInfo"))
                                                        var t1 = local['token']

                                                        const config = {
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                                "Authorization": `Bearer ${t1}`,
                                                            },
                                                        }
                                                        axios.post(baseUrl + "api/web/setLanguage", { settingsId, language, "method": "add" }, config).then(res => {

                                                            if (res.data.status == 1) {
                                                                setDataLoad(!dataLoad)
                                                                setShowForm(false)
                                                            }
                                                            else {

                                                                setIsLoading(false)

                                                            }
                                                        }).catch(err => {

                                                            setIsLoading(false)
                                                        })
                                                    }



                                                } else {
                                                    console.log("error");
                                                    setIsLoading(false)


                                                }

                                            }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>}
                        <Modal show={show} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete the Banner</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-center ">
                                    <h2 className="f-bold fs-4">Are you sure want to delete this language "{language}"</h2>
                                    <p> If any song upload as {language} language  will be changed to</p>
                                    {/* <Row className='d-inline-block'>
 */}
                                    <select
                                    className='mb-3 mt-3'
                                        value={altLang}
                                        onChange={(e) => {
                                            setAltLang(e.target.value)
                                        }}
                                    >
                                        {
                                            (() => {
                                                let container = [];
                                                if (songLanguage != null)
                                                    songLanguage.forEach((val) => {
                                                        if (language != val)
                                                            container.push(
                                                                <option value={val}>{val}</option>)
                                                    });
                                                return container;
                                            })()
                                        }

                                    </select>
                                    {/*  </Row> */}
                                    <Row className='d-inline '>
                                        <div className='d-flex justify-content-center'>
                                            <Button variant="danger" className="me-3" onClick={(e) => {
                                                e.preventDefault();

                                                var local = JSON.parse(localStorage.getItem("AdminInfo"))
                                                var t1 = local['token']
                                                const config = {
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        "Authorization": `Bearer ${t1}`,
                                                    },
                                                }

                                                axios.post(baseUrl + "api/web/setLanguage", { settingsId, language, "method": "remove", altLang }, config).then(res => {
                                                    if (res.data.status == 1) {
                                                        setShow(false)
                                                        setDataLoad(!dataLoad)
                                                    }
                                                    else {

                                                    }
                                                }).catch(err => {

                                                })
                                            }}>Delete</Button>
                                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                                        </div>
                                    </Row>
                                </div>

                            </Modal.Body>

                        </Modal>
                    </div>

                </div>
            }
        </div>
    )
}

export default LanguageSettings
