import React, { useState, useRef, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import UploadBredcumb from './UploadBredcumb'
import { Col, Tab, Nav } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux"

import AudioPlayer from './AudioPlayer'


import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../config/constant'

const LyricsUpload = () => {

        let history = useHistory()
        const userLogin = useSelector((state) => state.userLogin)
        const { UserInfo } = userLogin
        const [selectetTab, setselectetTab] = useState(0)
        const [loading, setLoading] = useState(false)
        const [err, setErr] = useState("")



        let lyricString = ""
        let lyrictoLine = ""
        var comp = ""
        var list = ""
        /*  var lyricsTemp = [] */
        var defaultText = []
        const [lyricsTemp, setLyricsTemp] = useState([])
        const [songSrc, setsongSrc] = useState('')
        /*  const [lyricsTemp, setLyricsTemp] = useState([{ "tab": true }, ...lyrics]) */


        // const audioRef = useRef()
        const editorRef = useRef()


        const updateSinger = (singer, value, id) => {

                console.log(singer)


                var temp = lyricsTemp
                temp[id][singer] = value
                console.log(temp)
                /*        console.log(temp[0][line]) */
                setLyricsTemp(temp)
                console.log(lyricsTemp)
                //dispatch(setLyrics(lyrics, 0))

        }
        useEffect(() => {
                setLoading(true)


                const userId = UserInfo._id

                var local = JSON.parse(localStorage.getItem("UserInfo"))

                var t1 = local['token']

                const config = {
                        headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${t1}`,
                        },
                }
                axios.post(
                        baseUrl + 'api/web/getuserdata',
                        {
                                userId,
                        },
                        config
                ).then(res => {

                        if (res.data.songDraft != null && res.data.songDraft.isDrafted) {
                                if (res.data.songDraft.lyricsTemp != undefined) {
                                        setLyricsTemp(res.data.songDraft.lyricsTemp)

                                        setsongSrc(res.data.songDraft.destination + "" + res.data.songDraft.fileName)

                                        editorRef.target.style.height = "1px";
                                        editorRef.target.style.height = (28 + editorRef.target.scrollHeight) + "px";
                                }


                        }

                }).catch(err => {
                        console.log(err)
                })

                setLoading(false)

        }, [UserInfo]);

        list = lyricsTemp.map(function (ln) {
                console.log("ln" + ln)
                if (ln.line === undefined) {

                        return (<></>)
                }
                if (ln.line === "") {
                        defaultText.push(ln.line)
                        return (
                                <ul className="group-check m-0 empty-height" key={ln._id}>&nbsp;</ul>
                        )
                } else {
                        defaultText.push(ln.line)
                        if (selectetTab === 0) {
                                return (<ul className="list-inline m-0 float-end duet-check" key={ln._id}>


                                        <li className="list-inline-item">
                                                <span className="custom_check">
                                                        <label className="control control--checkbox">
                                                                <input type="checkbox" defaultChecked={ln.ds1 ? true : false} onChange={e => updateSinger("ds1", e.target.checked, ln._id)} />
                                                                <span className="control__indicator"></span>
                                                        </label>
                                                </span>
                                        </li>

                                        <li className="list-inline-item">
                                                <span className="custom_check">
                                                        <label className="control control--checkbox">
                                                                <input type="checkbox" defaultChecked={ln.ds2 ? true : false} onChange={e => updateSinger("ds2", e.target.checked, ln._id)} />
                                                                <span className="control__indicator"></span>
                                                        </label>
                                                </span>
                                        </li>
                                </ul>)

                        }
                        else {
                                return (<ul className="list-inline m-0 float-end group-check" key={ln._id}>


                                        <li className="list-inline-item">
                                                <span className="custom_check">
                                                        <label className="control control--checkbox">
                                                                <input type="checkbox" defaultChecked={ln.gs1 ? true : false} onChange={e => updateSinger("gs1", e.target.checked, ln._id)} />
                                                                <span className="control__indicator"></span>
                                                        </label>
                                                </span>
                                        </li>
                                        <li className="list-inline-item">
                                                <span className="custom_check">
                                                        <label className="control control--checkbox">
                                                                <input type="checkbox" defaultChecked={ln.gs2 ? true : false} onChange={e => updateSinger("gs2", e.target.checked, ln._id)} />
                                                                <span className="control__indicator"></span>
                                                        </label>
                                                </span>
                                        </li>
                                        <li className="list-inline-item">
                                                <span className="custom_check">
                                                        <label className="control control--checkbox">
                                                                <input type="checkbox" defaultChecked={ln.gs3 ? true : false} onChange={e => updateSinger("gs3", e.target.checked, ln._id)} />
                                                                <span className="control__indicator"></span>
                                                        </label>
                                                </span>
                                        </li>
                                        <li className="list-inline-item">
                                                <span className="custom_check">
                                                        <label className="control control--checkbox">
                                                                <input type="checkbox" defaultChecked={ln.gs4 ? true : false} onChange={e => updateSinger("gs4", e.target.checked, ln._id)} />
                                                                <span className="control__indicator"></span>
                                                        </label>
                                                </span>
                                        </li>
                                </ul>)
                        }

                }
        })

        comp = <div className="row">
                <div className="col-xxl-6 col-xl-6  col-lg-6 col-sm-6 col-md-6 lyricsBaseWeb" role="presentation">
                        <textarea className="textarea-control" placeholder="Enter Lyrics Here"
                                ref={editorRef}

                                onFocus={(e) => {
                                        e.target.style.height = "1px";
                                        e.target.style.height = (28 + e.target.scrollHeight) + "px";
                                }}
                                onLoad={(e) => {
                                        e.target.style.height = "1px";
                                        e.target.style.height = (28 + e.target.scrollHeight) + "px";
                                }}
                                defaultValue={defaultText.join("\r\n")}
                                value={defaultText.join("\r\n")}
                                onMouseDown={(e) => {
                                        e.target.style.height = "1px";
                                        e.target.style.height = (28 + e.target.scrollHeight) + "px";
                                        var position = e.target.selectionStart
                                        e.target.setSelectionRange(position, position);
                                }}
                                onChange={(e) => {
                                        var position = e.target.selectionStart

                                        e.target.style.height = "1px";
                                        e.target.style.height = (28 + e.target.scrollHeight) + "px";
                                        lyricString = e.target.value
                                        /* console.log(e.target.value) */
                                        lyrictoLine = lyricString.split(/\r?\n/)
                                        let newdata = []
                                        let splited = []

                                        for (let index = 0; index < lyrictoLine.length; index++) {
                                                var str = lyrictoLine[index];
                                                var chunkSize = 45;
                                                if (str == "") {
                                                        splited.push("");
                                                }
                                                else {
                                                        while (str) {
                                                                if (str.length < chunkSize) {
                                                                        splited.push(str);
                                                                        break;
                                                                }
                                                                else {
                                                                        splited.push(str.substr(0, chunkSize));
                                                                        str = str.substr(chunkSize);
                                                                        console.log(position)
                                                                        console.log(position % chunkSize)
                                                                        if (position % chunkSize === 1) {
                                                                                console.log("a")
                                                                                position = position + 1;
                                                                                console.log(position)
                                                                        }

                                                                }
                                                        }

                                                }

                                        }
                                        for (let index = 0; index < splited.length; index++) {
                                                newdata.push({
                                                        "_id": index,
                                                        "line": splited[index],
                                                        "time": "00:00",

                                                })
                                        }
                                        setLyricsTemp(newdata)


                                        e.target.value = splited.join("\r\n")
                                        e.target.setSelectionRange(position, position);
                                        e.target.style.height = "1px";
                                        e.target.style.height = (28 + e.target.scrollHeight) + "px";

                                }
                                }

                        ></textarea>
                </div>
                <div className="col-xxl-6 col-xl-6  col-lg-6 col-sm-6 col-md-6">
                        <div className="col-xxl-6 col-xl-6  col-lg-6 col-sm-6 col-md-6 float-end" >
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="">

                                        <Col >
                                                <Nav variant="pills" className="flex-row dutesTabBase float-end">
                                                        <Nav.Item>
                                                                <Nav.Link eventKey="first" onClick={(e) => {
                                                                        setselectetTab(0)
                                                                }}>Duets</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                                <Nav.Link eventKey="second" onClick={(e) => {
                                                                        setselectetTab(1)
                                                                }}>Group</Nav.Link>
                                                        </Nav.Item>
                                                </Nav>
                                        </Col>
                                        <div className="clearfix"></div>

                                        <Tab.Content className="tabContentDuet float-end ">
                                                <Tab.Pane eventKey="first">
                                                        <ul className="list-inline m-0 ">
                                                                <li className="list-inline-item">
                                                                        <p>Singer 1</p>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                        <p>Singer 2</p>
                                                                </li>
                                                        </ul>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                        <ul className="list-inline m-0 groupSong ">
                                                                <li className="list-inline-item">
                                                                        <p>1</p>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                        <p>2</p>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                        <p>3</p>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                        <p>4</p>
                                                                </li>
                                                        </ul>
                                                </Tab.Pane>
                                        </Tab.Content>


                                </Tab.Container>
                                {list}
                        </div>





                </div>



        </div>

        return (
                <><UploadBredcumb activeStep={3} />

                        <div className="col p-0" >

                                <div className="container">
                                        <div className=" my-5 songUpload">
                                                <h1 className="text-center">Enter Lyrics</h1>
                                                <div className="col songUploadInner py-4">

                                                        <div className="col-12 p-5 text-left">
                                                                {comp}
                                                        </div>
                                                        <h3 className="mt-4"></h3>

                                                        <div className="text-center d-flex justify-content-center">
                                                                <button onClick={(e) => {
                                                                        setLyricsTemp([])


                                                                }}

                                                                        className="submitBtn float-start">Reset</button>
                                                        </div>


                                                </div>

                                                <div className=" pt-3 d-flex flex-row justify-content-between ">
                                                        <Link to="/musicdetails" className="submitBtn float-start"> Back </Link>
                                                        <div to="/lyricstiming" className="submitBtn float-end" onClick={(e) => {

                                                                console.log(lyricsTemp[0].line)
                                                                if (lyricsTemp != null && lyricsTemp.length > 0 && lyricsTemp.length != 1) {

                                                                        var userId = UserInfo._id


                                                                        var local = JSON.parse(localStorage.getItem("UserInfo"))

                                                                        var t1 = local['token']

                                                                        const config = {
                                                                                headers: {
                                                                                        'Content-Type': 'application/json',
                                                                                        "Authorization": `Bearer ${t1}`,
                                                                                },
                                                                        }
                                                                        axios.post(baseUrl + "api/web/musicdetailsstep3", { userId, lyricsTemp }, config).then(res => {
                                                                                setLoading(false)

                                                                                if (res.data.status == 1) {

                                                                                        history.push('lyricstiming')
                                                                                }
                                                                                else {

                                                                                }
                                                                        }).catch(err => {
                                                                                setLoading(false)
                                                                                setErr("Failed to update")

                                                                        })
                                                                }
                                                                else {
                                                                        setErr("fill the manditory field")
                                                                }

                                                        }}> Next </div>
                                                </div>
                                                <p className="mt-5"><sup>*</sup>  By uploading this song, you confirm that you are complying with all legal guidelines set forth by our Community Guidelines and Terms of Service.If you have any questions, please refer to our FAQ or contact YoYo Singer for more assistance.</p>
                                        </div>
                                </div>
                        </div>
                        <AudioPlayer
                                songSrc={songSrc}
                                // audioRef={audioRef}
                                onSeeked={(e) => { }}
                                onLoadedData={(e) => {
                                        console.log(e.currentTarget.duration)
                                        e.currentTarget.pause()

                                }}
                                onTimeUpdate={(e) => {
                                        console.log(e.currentTarget.currentTime)

                                }}
                        />

                </>
        )
}

export default LyricsUpload
