import React, { useEffect } from 'react'
import StickerCat from '../stickers/StickersCat'
import { Accordion } from 'react-bootstrap'
import Stickers from '../stickers/Stickers'
import AdminUserList from '../adminUsers/adminUserList'
import AdminFeaturedList from '../adminUsers/adminFeaturedList'
import AdminSongsList from '../songs/songsList'
import AdminReportedUsers from '../userreports/reportedUsers';
import AdminFrozenUsers from '../userreports/frozenUsers';
import AdminGiftCoins from "../coins/giftcoins";
import AdminSettings from "../adminfeatures/adminSettings";
import AppFeatureSetttings from "../adminfeatures/appfeaturesettings";
import BannerSettings from "../adminfeatures/bannerSettings";
import LanguageSettings from '../adminfeatures/languageSettings';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import TermsOfUse from '../pages/TermsOfUse';
import AboutUs from '../pages/AboutUs';
import Disclaimer from '../pages/Disclaimer';
import AdminAccessUserList from '../adminAccessUsers/adminAccessUserList';
import ImportFiles from '../fileManagement/importFiles';
import Template from '../emailTemplate/template'

import { BrowserRouter as Router, Link, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { adminLogout } from '../../store/actionCreators/userActions';
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import { Helmet } from "react-helmet";
import useState from 'react-usestateref'

const AdminSidebar = ({ match }) => {

    var urlarray = match.url.split('/')
    const [setlectdTab, setSetlectdTab] = useState("addstickercategory")
    const [privilege, setPrivilege, useRefPrivilege] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [meta, setMeta] = useState(null)

    let history = useHistory()
    const adminLogin = useSelector((state) => state.adminLogin)

    const dispatch = useDispatch()
    const { loading, error, AdminInfo } = adminLogin



    useEffect(() => {
        setIsLoading(true)
        if (AdminInfo != null && AdminInfo != "undefined") {
            setPrivilege(AdminInfo.user.privilege)
            setIsLoading(false)
        }
        else {
            history.push("/admin")
        }
    }, [AdminInfo])

    useEffect(() => {
        setIsLoading(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        axios.post(
            baseUrl + 'api/web/getmeta',
            {

            },
            config
        ).then(res => {


            if (res.data.meta != null) {

                setMeta(res.data.meta)

                console.log(res.data.meta)
            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }, [])

    const logoutHandler = () => {
        dispatch(adminLogout())
    }
    const matcher = window.matchMedia("(prefers-color-scheme: dark)");
    console.log(matcher.matches)
    var favIcon="fav1.ico";
    if(matcher.matches){
        favIcon="fav2.ico";
    }
    else{
        favIcon="fav1.ico";
    }

    return (

        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>{meta != null ? meta['siteName'] : "YOYO"}</title>
                
                <link rel="icon" href={favIcon} />
                <meta name="description" content={meta != null ? meta['metaDesc'] : "Yoyo"}></meta>
                <meta name="keywords" content={meta != null ? meta['metaKey'] : "Yoyo"}></meta>

                <meta property="og:title" content={meta != null ? meta['metaTitle'] : "Yoyo"}></meta>
                <meta property="og:url" content={meta != null ? meta['serverUrl'] : "https://www.yoyosinger.com"}></meta>

                <meta property="og:description" content={meta != null ? meta['metaDesc'] : "Yoyo"}></meta>
                <meta property="og:image" content={meta != null ? `${meta['serverUrl']}${meta['logoFolder']}${meta['logo']}` : "logo"}></meta>
            </Helmet>
        {isLoading ? <>loading</> : <div className="col   vh-100">

            <div className="wrapper h-100 m-0 overflow-hidden d-flex">
                <div className="adminSideBar h-100 adminThemebg">
                    <div className="d-flex  logo-container adminThemebg  justify-content-center py-2">
                        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" />
                    </div>
                    <div className="menu-container">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div className="menu-item  d-inline-block w-100 ">
                                        <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                        Dashboard
                                    </div>
                                </Accordion.Header>
                            </Accordion.Item>

                            {(AdminInfo.user.adminType.toLowerCase() == "admin") &&
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 ">
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            Admin
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                <li className={setlectdTab == "adminuserlist" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("adminuserlist")
                                                    }}

                                                >Admin Users</li>
                                                <li className={setlectdTab == "adminsettings" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("adminsettings")
                                                    }}

                                                >Admin Settings</li>
                                                <li className={setlectdTab == "appfeaturesetting" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("appfeaturesetting")
                                                    }}
                                                >App Feature Settings</li>
                                                <li className={setlectdTab == "bannersettings" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("bannersettings")
                                                    }}
                                                >Banner Settings</li>
                                                <li className={setlectdTab == "languagesettings" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("languagesettings")
                                                    }}
                                                >Language Settings</li>
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {((AdminInfo.user.adminType.toLowerCase() == "admin") || (useRefPrivilege.current.includes("userlist") || useRefPrivilege.current.includes("featuredlist"))) &&
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 ">
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            Users
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                {((AdminInfo.user.adminType.toLowerCase() == "admin") || (useRefPrivilege.current.includes("userlist")) )&& <li className={setlectdTab == "userlist" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("userlist")
                                                    }}

                                                >Users List</li>}
                                                {((AdminInfo.user.adminType.toLowerCase() == "admin") || (useRefPrivilege.current.includes("featuredlist"))) && <li className={setlectdTab == "featuredlist" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("featuredlist")
                                                    }}
                                                >Featured User</li>}
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {((AdminInfo.user.adminType.toLowerCase() == "admin") || (useRefPrivilege.current.includes("songslist"))) &&
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 ">
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            Songs
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                {((AdminInfo.user.adminType.toLowerCase() == "admin") || (useRefPrivilege.current.includes("songslist"))) &&

                                                    <li className={setlectdTab == "songslist" ? "active" : "inactive"}
                                                        onClick={(e) => {
                                                            setSetlectdTab("songslist")
                                                        }}

                                                    >Songs List</li>
                                                }

                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {((AdminInfo.user.adminType.toLowerCase() == "admin") || (useRefPrivilege.current.includes("addstickercategory")) || (useRefPrivilege.current.includes("addsticker"))) &&
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 " >
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            Stickers
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                {((AdminInfo.user.adminType.toLowerCase() == "admin") || (useRefPrivilege.current.includes("addstickercategory"))) && <li className={setlectdTab == "addstickercategory" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("addstickercategory")
                                                    }}

                                                >Categorys</li>}
                                                {((AdminInfo.user.adminType.toLowerCase() == "admin") || (useRefPrivilege.current.includes("addsticker"))) && <li className={setlectdTab == "addsticker" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("addsticker")
                                                    }}
                                                >Stickers</li>}
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("reportedusers")) || (useRefPrivilege.current.includes("reportedusers"))) &&
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 ">
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            User Reports
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div class="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("reportedusers"))) && <li className={setlectdTab == "reportedusers" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("reportedusers")
                                                    }}

                                                >Reported User</li>}
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("frozenusers"))) && <li className={setlectdTab == "frozenusers" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("frozenusers")
                                                    }}
                                                >Frozen User</li>}
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("giftcoins"))) &&
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 " >
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            Coins
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div class="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("giftcoins"))) && <li className={setlectdTab == "giftcoins" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("giftcoins")
                                                    }}

                                                >Gift Coins</li>}
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("importfiles"))) &&
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 " >
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            Files
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div class="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("importfiles"))) && <li className={setlectdTab == "importfiles" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("importfiles")
                                                    }}

                                                >Import Files</li>}
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("privacypolicy")) || (useRefPrivilege.current.includes("termsofuse")) || (useRefPrivilege.current.includes("aboutus")) || (useRefPrivilege.current.includes("disclaimer"))) &&
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 " >
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            Pages
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div class="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("privacypolicy"))) && <li className={setlectdTab == "privacypolicy" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("privacypolicy")
                                                    }}

                                                >Privacy Policy</li>}
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("termsofuse"))) && <li className={setlectdTab == "termsofuse" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("termsofuse")
                                                    }}

                                                >Terms of use</li>}
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("aboutus"))) && <li className={setlectdTab == "aboutus" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("aboutus")
                                                    }}

                                                >About Us</li>}
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("disclaimer"))) && <li className={setlectdTab == "disclaimer" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("disclaimer")
                                                    }}

                                                >Disclaimer</li>}
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("templates"))) &&
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>
                                        <div className="menu-item  d-inline-block w-100 " >
                                            <span className="menu-icons"> <img src={require("../../images/dashboard.png").default} alt="dashboard" /></span>
                                            Email Templates
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div class="">
                                            <ul className="list-unstyled admin-dropdownmenu">
                                                {(AdminInfo.user.adminType.toLowerCase() == "admin" || (useRefPrivilege.current.includes("templates"))) && <li className={setlectdTab == "templates" ? "active" : "inactive"}
                                                    onClick={(e) => {
                                                        setSetlectdTab("templates")
                                                    }}

                                                >Add Template</li>}
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                        </Accordion>

                    </div>
                </div>
                <div className=" adminMainContent w-100 ">
                    <div className=" header-content w-100 py-2  adminThemebg d-flex justify-content-between">
                        <div className="menuIcon pointer:cusrdor float-start align-items-center pt-1">
                            <h1 className="f-regular text-light fs-6 m-0">Hi Admin</h1>
                        </div>
                        <div className="notifyIcons float-end pe-3">
                            <ul className="list-inline m-0">
                                <li className="list-inline-item">
                                    <a href="">  <img src={require("../../images/notification.png").default} alt="dashboard" /></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#" onClick={() => logoutHandler()} className=" f-regular  primaryColor  text-decoration-none my-4"><span className="keyIcon"></span> Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="">

                        {(setlectdTab == "addstickercategory" && (useRefPrivilege.current.includes("addstickercategory") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <StickerCat />}
                        {(setlectdTab == "addsticker" && (useRefPrivilege.current.includes("addsticker") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <Stickers />}
                        {(setlectdTab == "userlist" && (useRefPrivilege.current.includes("userlist") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AdminUserList />}
                        {(setlectdTab == "featuredlist" && (useRefPrivilege.current.includes("featuredlist") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AdminFeaturedList />}
                        {(setlectdTab == "songslist" && (useRefPrivilege.current.includes("songslist") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AdminSongsList />}
                        {(setlectdTab == "reportedusers" && (useRefPrivilege.current.includes("reportedusers") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AdminReportedUsers />}
                        {(setlectdTab == "frozenusers" && (useRefPrivilege.current.includes("frozenusers") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AdminFrozenUsers />}
                        {(setlectdTab == "giftcoins" && (useRefPrivilege.current.includes("giftcoins") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AdminGiftCoins />}
                        {(setlectdTab == "adminsettings" && (useRefPrivilege.current.includes("adminsettings") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AdminSettings />}
                        {(setlectdTab == "appfeaturesetting" && (useRefPrivilege.current.includes("appfeaturesetting") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AppFeatureSetttings />}
                        {(setlectdTab == "bannersettings" && (useRefPrivilege.current.includes("bannersettings") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <BannerSettings />}
                        {(setlectdTab == "privacypolicy" && (useRefPrivilege.current.includes("privacypolicy") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <PrivacyPolicyPage />}
                        {(setlectdTab == "termsofuse" && (useRefPrivilege.current.includes("termsofuse") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <TermsOfUse />}
                        {(setlectdTab == "aboutus" && (useRefPrivilege.current.includes("aboutus") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AboutUs />}
                        {(setlectdTab == "disclaimer" && (useRefPrivilege.current.includes("disclaimer") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <Disclaimer />}
                        {(setlectdTab == "adminuserlist" && (useRefPrivilege.current.includes("adminuserlist") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <AdminAccessUserList />}
                        {(setlectdTab == "importfiles" && (useRefPrivilege.current.includes("importfiles") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <ImportFiles />}
                        {(setlectdTab == "templates" && (useRefPrivilege.current.includes("templates") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <Template />}
                        {(setlectdTab == "languagesettings" && (useRefPrivilege.current.includes("languagesettings") || AdminInfo.user.adminType.toLowerCase() == "admin")) && <LanguageSettings />}




                    </div>
                </div>
            </div>

        </div>}</>

    )
}

export default AdminSidebar
