import react, { useEffect } from 'react';
import { useRef } from "react";
import white_play from "../../images/white_play.png";
import volume from '../../images/volume.svg'
import pause from '../../images/pause.svg'
import mute from '../../images/mute.svg'
import google_play_store from '../../images/google_play_store.webp'
import apple from '../../images/apple.png'
import Slider from 'react-rangeslider'
import axios from 'axios';
import useState from 'react-usestateref'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { baseUrl } from '../../config/constant';




const Player = () => {
   
    let location = useLocation();
    const songRef = location.pathname.split('/')[location.pathname.split('/').length - 1]

    const [isPlaying, setIsPlaying] = useState(false)
    const [mediaTotalTime, setMediaTotalTime] = useState(0);
    const [mediaCurrentTime, setMediaCurrentTime] = useState(0);
    const [sliderRange, setSliderRange] = useState(0);
    const [soundVolume, setSoundVolume] = useState(10);
    const [onSound, setOnSound] = useState(false);
    const [isLoading, setIsLoading, useRefIsLoading] = useState(false);
    const [file, setFile, useRefFile] = useState("");
    const [coverPic, setCoverPic, useRefCoverPic] = useState("");
    const [responseData, setResponseData, useRefResponseData] = useState(null);
    const [serverUrl, setServerUrl, useRefServerUrl] = useState();
    const [profilImgUrl, setProfilImgUrl, useRefProfilImgUrl] = useState();
    const [androidAppLink, setAndroidAppLink, useRefAndroidAppLink] = useState();
    const [iosAppLink, setIosAppLink, useRefIosAppLink] = useState();




    /* const [sound, setSound] = useState(0); */
    const mediaRef = useRef(null);

    window.setInterval(function () {
        setMediaCurrentTime(mediaRef.current?.currentTime);
    }, 1000);

    useEffect(() => {
        console.log(songRef)
        console.log("songRef")
        setIsLoading(true)
        const config = { headers: { 'Content-Types': 'application/json', }, }


        axios.post(baseUrl + 'api/web/getrecordedonesong', { songRef: songRef }, config).then(res => {

            setServerUrl(res.data.serverUrl)
            setAndroidAppLink(res.data.androidAppLink)
            setIosAppLink(res.data.iosAppLink)
            setProfilImgUrl(res.data.profilImgUrl)
            setFile(res.data.serverUrl + res.data.recordings['fileStoreAt'] + res.data.recordings['file'])
            setCoverPic(res.data.serverUrl + res.data.recordings['songRef']['coverDest'] + res.data.recordings['songRef']['coverPic'])
            setResponseData(res.data.recordings)
            setIsLoading(false)

        })

    }, [songRef]);

    const videoHandler = () => {
        console.log(mediaRef)

        if (mediaRef.current.paused) {
            mediaRef.current.play();
            setIsPlaying(true)

            setMediaTotalTime(mediaRef.current.duration);

            setSoundVolume(mediaRef.current.volume * 10);

        } else {
            mediaRef.current.pause();
            setIsPlaying(false)
        }
    };

    const handleOnChange = (value) => {
        setMediaCurrentTime(value);
        mediaRef.current.currentTime = value;
        mediaRef.current.play();
        setIsPlaying(true)

    }

    const handleOnVolumeChange = (value) => {
        setSoundVolume(value);
        mediaRef.current.volume = value / 10;
        if (mediaRef.current.volume > 0) {
            setOnSound(false)
        }
        else {
            setOnSound(true)
        }
    }

    const handleOnSound = (value) => {

        if (onSound) {
            mediaRef.current.volume = 1
            setSoundVolume(mediaRef.current.volume * 10);
            setOnSound(false)
        }
        else {

            mediaRef.current.volume = 0
            setSoundVolume(mediaRef.current.volume * 10);
            setOnSound(true)

        }
    };


    return (
        <div className='col-12 share-screen-base'>
            {(useRefIsLoading.current || useRefResponseData.current == null) ?
                <div>loading</div>
                :
                <Container>
                    <div className='justify-content-center d-flex align-items-center share-screen-base mt-3'>
                    <Row>

                        <Col lg={6} xxl={6} xl={6} md={12} sm={12} xs={12} className="music-card-base mb-5">

                            <div className="app w-100">
                                {(file.split('.').pop() == "mp4") ? <video ref={mediaRef} className="video video_card"
                                    src={file} >
                                </video> :
                                    <div className="position-relative">
                                        {/*   <img src={coverPic} alt="music_cardpic" className="music_card" width={'35%'} /> */}
                                        <img src={coverPic} alt="music_cardpic" className="music_card" width={'35%'} />
                                        <audio controls={false} ref={mediaRef}  >
                                            <source src={file} />
                                        </audio>
                                        <Col lg={4} xxl={4} xl={4} md={4} sm={12} xs={12} className='volume-controls'>
                                                <Row>
                                                    
                                                    <Col lg={12} xxl={12} xl={12} md={12} sm={12} xs={12} className='m-auto'>
                                                        <span className='d-inline-block'>  
                                                        <img
                                                            onClick={() => handleOnSound()}
                                                            className="volume-icon"
                                                            alt="volume"
                                                            src={
                                                                !onSound
                                                                    ? volume : mute
                                                            }/>
                                                        </span>
                                                   
                                                        {/*  <img src={volume} alt="volume" className="volume-icon" > </img> */}
                                                        <Slider className="volume-horizontal"
                                                            min={0}
                                                            max={10}
                                                            value={soundVolume}
                                                            orientation="horizontal"
                                                            onChange={handleOnVolumeChange}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Col>
                                        <div className="controlsContainer">
                                            <div className='row mx-0'>
                                            <div className="controls col-2 col-lg-1 col-xxl-1 col-xl-1 col-md-1 col-sm-1">
                                                <img
                                                    onClick={() => videoHandler()}
                                                    className=""
                                                    alt="play"
                                                    src={
                                                        !isPlaying
                                                            ? require("../../images/play.svg").default :  require("../../images/pause.svg").default
                                                    }
                                                    width={20}
                                                    height={20}
                                                />
                                            </div>
                                            <div className="timecontrols col-10 col-lg-11 col-xxl-11 col-xl-11 col-md-11 col-sm-11">
                                                <div className="player_class">
                                                    <div style={{ width: (mediaCurrentTime / mediaTotalTime) * 100 + "%" }} className="player_class"></div>
                                                    <Row>
                                                        <Col lg={12} xxl={12} xl={12} md={12} sm={12} xs={12} className="p-0">
                                                            <Row>
                                                               {/*  <Col lg={3} xxl={3} xl={3} md={3} sm={3} xs={12}>
                                                                    <p className="controlsTime">{Math.floor(mediaCurrentTime / 60) + ":" + ("0" + Math.floor(mediaCurrentTime % 60)).slice(-2)}</p>
                                                                </Col> */}
                                                                <Col lg={11} xxl={11} xl={11} sm={10} md={11} xs={10} className=' m-auto'>
                                                                    {<Slider
                                                                        min={0}
                                                                        max={mediaTotalTime}
                                                                        value={mediaCurrentTime}
                                                                        tooltip={false}
                                                                        orientation="horizontal"
                                                                        onChange={handleOnChange}
                                                                    />}

                                                                </Col>
                                                                <Col lg={1} xxl={1} xl={1} md={1} sm={2} xs={2}  className="p-0" >
                                                                    <p className="controlsTime-end "> {Math.floor((mediaTotalTime) / 60) + ":" + ("0" + Math.floor(mediaTotalTime % 60)).slice(-2)}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        
                                                    </Row>

                                                </div>
                                            </div>
                                            </div>
                                         </div>

                                         <div className='liked-base position-absolute mb-0'>
                                         

                                                        <ul className="list-unstyled mb-0">
                                                            <li className=' text-center mb-2'>
                                                                <img src={require("../../images/heart.svg").default} alt="likes" class="music-icons" />
                                                                <p className='text-light f-light'> {useRefResponseData.current.likes.length} </p>
                                                            </li>
                                                            <li className='ms-2 text-center mb-2'>
                                                                <img src={require("../../images/gift.svg").default} alt="gifts" class="music-icons" />
                                                                <p className='text-light f-light'> {useRefResponseData.current.gifts.length}  </p>
                                                            </li>
                                                            <li className=' ms-2 text-center mb-2'>
                                                                <img src={require("../../images/comment.svg").default} alt="comments" class="music-icons" />
                                                                <p className='text-light f-light'> {useRefResponseData.current.comments.length} </p>
                                                            </li>
                                                        </ul>
                                                        
                                        </div>
                                        
                                    </div>}
                               
                                


                            </div>



                        </Col>

                        <Col lg={6} xxl={6} xl={6} md={12} sm={12} xs={12} className="mb-5">
                            <Row className=" mx-0">
                                <Col className="p-0">
                                    <ul className='list-inline profile-photo-base'>
                                        <li className="list-inline-item profile-pic position-relative pe-4">
                                            {
                                                (() => {
                                                    let container = [];
                                                    if (useRefResponseData.current != null)
                                                        useRefResponseData.current.singers.forEach((val, index) => {
                                                            if (index >= 2)
                                                                return
                                                            container.push(
                                                                <span className=' d-inline-block'>
                                                                    <img src={serverUrl + profilImgUrl + val["profilePic"]} class="music-profile img-position" />
                                                                </span>)
                                                        });
                                                    return container;
                                                })()
                                            }


                                        </li>
                                        <li className="list-inline-item align-middle">
                                            <h5 className='m-0 f-bold'>    {
                                                (() => {
                                                    let container = [];
                                                    if (useRefResponseData.current != null)
                                                        useRefResponseData.current.singers.forEach((val, index) => {
                                                            if (index < 2)
                                                                container.push(
                                                                    <span className=' f-bold'>
                                                                        {val['userName']}
                                                                    </span>)
                                                            else
                                                                container.push(

                                                                    <span className=' f-bold'>
                                                                        {`${useRefResponseData.current.singers.length - 2} other${useRefResponseData.current.singers.length - 2 > 1 ? 's' : ''}`}
                                                                    </span>)
                                                            if (useRefResponseData.current.singers.length == 1) {
                                                                return
                                                            }
                                                            if (useRefResponseData.current.singers.length == 2 && index < 1) {
                                                                container.push(

                                                                    <span className=' f-bold'>
                                                                        {` & `}
                                                                    </span>)
                                                            }
                                                            if (useRefResponseData.current.singers.length > 2) {
                                                                if (1 == index)
                                                                    container.push(

                                                                        <span className=' f-bold'>
                                                                            {` & `}
                                                                        </span>)
                                                                else if (index < 1)
                                                                    container.push(

                                                                        <span className=' f-bold'>
                                                                            {`, `}
                                                                        </span>)
                                                            }
                                                        });
                                                    return container;
                                                })()
                                            } </h5>

                                            <p className='f-light'>
                                                {useRefResponseData.current.comment} </p>
                                        </li>

                                    </ul>
                                </Col>



                            </Row>
                            <Row className=''>
                                <h3 className="music-theme-clr f-semibold">{useRefResponseData.current.songRef.songTitle} </h3>
                            </Row>
                            <Row>
                                <p className='greytext'><span> <img src={require("../../images/playblack.svg").default} alt="likes" width={15} height={15} /></span> {useRefResponseData.current.played.length} {/* <span className="ms-2">  {useRefResponseData.current.createdAt}.</span> */} </p>
                            </Row>
                          
                            
                            <Row className='mt-5'>
                                <Col className="float-start  ">
                                    <a href={androidAppLink} target={"_blank"} ><img src={google_play_store} className="download-icons"></img> </a>
                                    <a href={iosAppLink} target={"_blank"}><img src={apple} className="download-icons"></img> </a>

                                    {/*   <button type="button" className="btn install-btn d-inline-block">Install app</button> */}

                                </Col>
                            </Row>

                        </Col>

                    </Row>
                    </div>
                </Container>
            }
        </div>
    );
}

export default Player
