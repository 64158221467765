import {combineReducers} from 'redux'
import { AdminLoginReducer } from './AdminLoginReducer'
import {LyricsReducer} from "./LyricsReducer"
import { UserLoginReducer } from './UserLoginReducer'
import { UserRegisterReducer } from './UserRegisterReducer'
const reducers = combineReducers({
    userLogin:UserLoginReducer,
    userRegister:UserRegisterReducer,
    lyrics:LyricsReducer,
    adminLogin:AdminLoginReducer,
})

export default reducers