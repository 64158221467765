import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Spinner } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import { baseUrl } from '../../config/constant';

const Template = () => {

    const [tempalteData, setTempalteData] = useState("")
    const [emailTemplateId, setEmailTemplateId] = useState(null)
    const [dataLoad, setDataLoad] = useState(false)
    const [tableData, setTableData] = useState([])
    const [deleteId, setDeleteId] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [privacyPolicy, setPrivacyPolicy] = useState("")
    const editorRef = useRef(null);
    const [fileName, setFileName] = useState("")
    const [templateName, setTemplateName] = useState("")
    const [showForm, setShowForm] = useState(false)
    const [show, setShow] = useState(false);
    const [tabelData, settabelData] = useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        axios.post(
            baseUrl + 'api/web/getallemailtemplates',
            {

            },
            config
        ).then(res => {
           

            if (res.data.emailTemplates != null) {
            
                var data = [];
                res.data.emailTemplates.map((listValue, index) => {
                    data.push({
                        col1: index + 1,
                        col2: listValue.templateName,
                        col3: listValue.fileName,
                        col4: <ul className="list-inline m-0">
                            <li className="list-inline-item pe-2 pe-auto"  data-toggle="tooltip" data-placement="top" title="Edit"><img src={require("../../images/edit.svg").default}  onClick={(e) => {
                                setEmailTemplateId(listValue._id)
                                setShowForm(true)
                                setFileName(listValue.fileName)
                                setTemplateName(listValue.templateName)
                                setTempalteData(listValue.templateContent)
                                 console.log(listValue.fileName);
                            }} alt="edit" /></li>

                            <li className="list-inline-item"  ><img src={require("../../images/delete.svg").default} alt="delete" onClick={(e) => { setShow(true); setDeleteId(listValue._id) }} /></li>

                        </ul>,
                      
                       
                    });

                });
                settabelData(data)
                setDataLoad(true)

            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }, [dataLoad]);


    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Template Name',
                accessor: 'col2',
            },
            {
                Header: 'file Name',
                accessor: 'col3',
            },
            
            {
                Header: 'Action',
                accessor: 'col4',
            },
           
        ],
        [tabelData]
    )

    var data1 = React.useMemo(
        () => tabelData,
        [tabelData]
    )

    return (
        <>
        {isLoading ?
        <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                    <Spinner animation="grow" />
                </div>:
        <div className="col  vh-100">
            <div className="container h-100">
               
           {!showForm && <div className="">
                    <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <h3 className="f-semibold m-0">Total No of Email Templates</h3>
                            <h3 className="f-bold ps-5 m-0">{tabelData.length}</h3>
                        </div>
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <button className="adminThemebtn" onClick={(e) => {
                                setShowForm(true)
                             
                            }}>Add Template</button>
                        </div>
                    </div>
                    <div className="table-responsive adminTable ">
                        <DataTable columns={columns} data={data1} />
                    </div>

                </div>}
                {showForm &&  <div className="col  vh-100">
                    <div className="container h-100 mt-5">
                        <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                            <label className="text-dark mb-1">File Name</label>
                            <input type="text" className="input-control m-0 w-100" value={fileName} onChange={e => setFileName(e.target.value)} />
                        </div>
                        <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                            <label className="text-dark mb-1">Template Name</label>
                            <input type="text" className="input-control m-0 w-100"  value={templateName} onChange={e => setTemplateName(e.target.value)} />
                        </div>
                        <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={tempalteData}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                            onChange={
                                (e) => {
                                    console.log(editorRef.current.getContent());
                                    setTempalteData(editorRef.current.getContent());
                                }
                            }
                        />
                        <div className='my-5 justify-content-between d-flex '>
                        <button class="adminThemebtn bg-light  text-dark"  onClick={(e) => {setShowForm(false) }}>Cancel</button>
                            <button className="adminThemebtn" onClick={(e) => {
                                e.preventDefault();
                                setIsLoading(true)
                                var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                var t1 = local['token']
                        
                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "Authorization": `Bearer ${t1}`,
                                    },
                                }
                                axios.post(baseUrl+"api/web/addemailtemplate", { emailTemplateId: emailTemplateId, templateName: templateName, fileName: fileName, templateContent: tempalteData }, config).then(res => {

                                    console.log(res.data)
                                    if (res.data.status == 1) {
                                        setDataLoad(!dataLoad)
                                        setIsLoading(false)
                                        setShowForm(false)
                                    }
                                    else {

                                    }
                                }).catch(err => {

                                })



                            }}>Submit</button>

                          
                        </div>

                    </div>

                </div>    }
            
                 </div>
                 <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete the Email Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center ">
                        <h2 className="f-bold fs-4">Are you sure want to delete the Email Template </h2>
                        <Button variant="danger" className="me-3" onClick={(e) => {
                            e.preventDefault();
                            var local = JSON.parse(localStorage.getItem("AdminInfo"))

                            var t1 = local['token']
                    
                            const config = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "Authorization": `Bearer ${t1}`,
                                },
                            }

                            axios.post(baseUrl +"api/web/deleteemailtemplate", { emailTemplateId:deleteId, }, config).then(res => {

                                console.log(res.data)
                                if (res.data.status == 1) {

                                    setShow(false)
                                    setDataLoad(!dataLoad)

                                }
                                else {

                                }
                            }).catch(err => {
                                
                            })
                        }}>Delete</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    </div>

                </Modal.Body>

            </Modal> 
            </div>}
        </>
    )
}

export default Template
