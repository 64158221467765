import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Spinner } from 'react-bootstrap'

const AdminSongsList = () => {
    const [songList, setSongList] = useState([])
    const [serverUrl, setServerUrl] = useState("")
    const [dataLoad, setDataLoad] = useState(false)
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const [audioRef, setAudioRef] = useState("");
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {

    }, [audioRef]);

    useEffect(() => {

        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        axios.post(
            baseUrl + 'api/web/getSongsList',
            {

            },
            config
        ).then(res => {
            console.log(res.data)
            if (res.data.songsList != null) {
                setSongList(res.data.songsList)
                setServerUrl(res.data.serverUrl)
                var data = [];


                songList.map((listValue, index) => {

                    data.push({
                        col1: index + 1,
                        col2:
                            <div>
                                <h4>{listValue.songTitle}</h4>
                                <audio
                                    onPlay={(e) => {
                                       
                                        var audios = document.getElementsByTagName('audio');
                                        for (var i = 0, len = audios.length; i < len; i++) {
                                            if (audios[i] != e.target) {
                                                audios[i].pause();
                                            }
                                        }
                                    }}
                                   


                                    src={serverUrl + listValue.destination + listValue.fileName} controls controlsList="nodownload" />
                            </div>,
                        col3: <img src={serverUrl + listValue.coverDest + listValue.coverPic} width="80" height="80" />,
                        col4: listValue.uploader.userName,
                        col5: listValue.songLanguage,
                        col6: <ul className="list-inline m-0">
                            <li className="list-inline-item"  ><img src={require("../../images/delete.svg").default} alt="delete" onClick={(e) => { setShow(true); setDeleteId(listValue._id) }} /></li>
                        </ul>,
                        col7: listValue.songTitle,

                    });

                });
                console.log(data)
                setTableData(data)
                setDataLoad(true)

            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })

    }, [dataLoad]);


    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Song Name',
                accessor: 'col2',
            },
            {
                Header: 'Song Album',
                accessor: 'col3',
            },
            {
                Header: 'Uploader',
                accessor: 'col4',
            },
            {
                Header: 'Language',
                accessor: 'col5',
            },
            {
                Header: 'Delete',
                accessor: 'col6',
            },
            {
                Header: 'Title',
                accessor: 'col7',
            },
        ],
        [tableData]
    )

    var data1 = React.useMemo(
        () => tableData,
        [tableData]
    )

    return (
        <div className="col  vh-100">
            <div className="container h-100">
                {true && <div className="">
                    <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <h3 className="f-semibold m-0">Total No of songs</h3>
                            <h3 className="f-bold ps-5 m-0">{tableData.length}</h3>
                        </div>
                    </div>
                    <div className="table-responsive adminTable songListTable">
                        {isLoading ?
                            <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                                <Spinner animation="grow" />
                            </div>
                            :
                            <DataTable columns={columns} data={data1} />}
                    </div>

                </div>}
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete the Banner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center ">
                            <h2 className="f-bold fs-4">Are you sure want to delete this song</h2>
                            <Button variant="danger" className="me-3" onClick={(e) => {
                                var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                var t1 = local['token']
                                e.preventDefault();
                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "Authorization": `Bearer ${t1}`,
                                    },
                                }

                                axios.post(baseUrl + "api/web/deletesong", { deleteId, }, config).then(res => {

                                    console.log(res.data)
                                    if (res.data.status == 1) {

                                        setShow(false)
                                        setDataLoad(!dataLoad)

                                    }
                                    else {

                                    }
                                }).catch(err => {

                                })
                            }}>Delete</Button>
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                        </div>

                    </Modal.Body>

                </Modal>
            </div>

        </div>

    )
}

export default AdminSongsList
