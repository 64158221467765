import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button ,Spinner} from 'react-bootstrap'
import { baseUrl } from '../../config/constant';
import { Editor } from '@tinymce/tinymce-react';

const AboutUs = () => {
   
    const [pageData, setPageData] = useState("")
    const [dataLoad, setDataLoad] = useState(false)
    const [tableData, setTableData] = useState([])  
    const [isLoading, setIsLoading] = useState(false)    
    const [privacyPolicy, setPrivacyPolicy] = useState("")    
    const editorRef = useRef(null);

    useEffect(() => {
        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }

        axios.post(
            baseUrl + 'api/web/getpagedata',
            {

            },
            config
        ).then(res => {
            if (res.data.pageData != null) {
              
                setPrivacyPolicy(res.data.pageData ["aboutUs"])
                setPageData(res.data.pageData ["aboutUs"])
                console.log(res.data.pageData)
              console.log(privacyPolicy)
              setIsLoading(false)
            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }, [dataLoad]);



    return (
        <>
        {isLoading ?
            <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                <Spinner animation="grow" />
            </div>
            :
            
        <div className="col  vh-100">
            <div className="container h-100 mt-5">
                <h1 className='f-bold my-5'>About Us</h1>
            <Editor
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue={privacyPolicy}
         init={{
           height: 500,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | help |code',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         }}
         onChange={
            (e) => {
                console.log(editorRef.current.getContent());
                setPageData(editorRef.current.getContent());
            }
         }
       />
           <div className='my-5'>
           <button className="adminThemebtn" onClick={(e) => {
                                e.preventDefault();
                                setIsLoading(true)
                                var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                var t1 = local['token']
                        
                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "Authorization": `Bearer ${t1}`,
                                    },
                                }
                        
                                    axios.post( baseUrl +"api/web/setpagedata", {field:"aboutUs",value:pageData  }, config).then(res => {

                                        console.log(res.data)
                                        if (res.data.status == 1) {
                                          setDataLoad(!dataLoad)
                                          setIsLoading(false)
                                        }
                                        else {

                                        }
                                    }).catch(err => {
                                      
                                    })
                            


                            }}>Submit</button>
           </div>
               
            </div>
            
        </div>}
        </>
    )
}

export default AboutUs
