import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button,Spinner } from 'react-bootstrap'

const AdminFrozenUsers = () => {
    const [userList, setUserList] = useState([])
    const [serverUrl, setServerUrl] = useState("")
    const [profilePicUrl, setProfilePicUrl] = useState("")
    const [coverPicUrl, setCoverPicUrl] = useState("")
    const [dataLoad, setDataLoad] = useState(false)
    const [tableData, setTableData] = useState([])    
    const [isLoading, setIsLoading] = useState(false)    
    const [reportId, setReportId] = useState("")  
    const [reportedUserId, setReportedUserId] = useState("")  
    const [method, setMethod] = useState("")  
    const [isChecked, setIsChecked] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [actionType, setActionType] = useState(false)
    const [selectedActionType, setSelectedActionType] = useState("freeze")
    const [errorMsg, setErrorMsg] = useState("")
   

    useEffect(() => {
        console.log("inside use effect")
        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }

        axios.post(
            baseUrl +'api/web/getfrozenuserslist',
            {

            },
            config
        ).then(res => {
            if (res.data.userList != null) {
                setUserList(res.data.userList)
                var data = [];
               

                userList.map((listValue, index) => {
                    data.push({
                        col1: index + 1,
                        col2: listValue.userName,
                        col3: listValue.gender,
                        col4:  listValue.email,
                        col5: listValue.userName,
                        col6: listValue.updatedAt,
                        col7: <Button className="btn btn-danger f-regular"
                        onClick={(e) => {
                            setReportedUserId(listValue.userName)
                            e.preventDefault();
                            var local = JSON.parse(localStorage.getItem("AdminInfo"))

                            var t1 = local['token']
                    
                            const config = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "Authorization": `Bearer ${t1}`,
                                },
                            }
                    
                            var unFreezeUser = listValue._id
                         
                            axios.post(baseUrl + "api/web/setunfreezeuser", {unFreezeUser}, config).then(res => {

                                console.log(res.data)
                                if (res.data.status == 1) {
                                    setDataLoad(!dataLoad)
                                    setErrorMsg("")
                                }
                                else {
                                        setErrorMsg(res.data.message)
                                }
                            }).catch(err => {
                                setErrorMsg("Something Went Wrong")
                            })
                        }}
                        >Unfreeze</Button>,
                        
                    });

                });
                console.log(data)
                setTableData(data)
                setDataLoad(true)

            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })

    }, [dataLoad]);

    const handleOnChange = (e) => {

         setSelectedActionType(e.target.value);
       console.log(selectedActionType);
       
      
        
    };
    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'col2',
            },
            {
                Header: 'Gender',
                accessor: 'col3',
            },
            {
                Header: 'EmailID',
                accessor: 'col4',
            },
            {
                Header: 'Reported By',
                accessor: 'col5',
            },
            {
                Header: 'Frozen Date',
                accessor: 'col6',
            },
            {
                Header: 'Action',
                accessor: 'col7',
            },
            
           
        ],
        [tableData]
    )

    var data1 = React.useMemo(
        () => tableData,
        [tableData]
    )

    return (
        <div className="col  vh-100">
            <div className="container h-100">
               <div className="">
                    <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <h3 className="f-semibold m-0">Total No of Users</h3>
                            <h3 className="f-bold ps-5 m-0">{tableData.length}</h3>
                        </div>
                    </div>
                    <div className="table-responsive adminTable ">
                        {isLoading ?
                        <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                            <Spinner animation="grow" />
                        </div>
                        :
                        <DataTable columns={columns} data={data1} />}
                    </div>

                </div>

        
            </div>
            
        </div>

    )
}

export default AdminFrozenUsers
