import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import { adminLoginCheck } from '../../store/actionCreators/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Spinner } from 'react-bootstrap'
const AdminLanding = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")


    const history = useHistory()
    const dispatch = useDispatch()
    const adminLogin = useSelector((state) => state.adminLogin)
    const { loading, error, AdminInfo } = adminLogin

    useEffect(() => {
        console.log(adminLogin)
        if (AdminInfo != null && AdminInfo != "undefined") {
            history.push("/admin/dashboard")
        }
        else {

        }
    }, [AdminInfo])


    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(adminLoginCheck(email, password))
        if ((error == "" || error == null)) {
            history.push("/admin/dashboard")
        }
    }
    return (
        <div className="col adminLanding vh-100">
            <div className="container h-100">
                <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 align-items-center  d-flex justify-content-center  h-100">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-4 login-container ">

                    
                        <form>
                            <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 text-center col-sm-12 mb-4">
                                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Singing" />
                            </div>
                            {error &&<p className='error'>{error}</p>}
                            <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <label className="text-light mb-1">Username</label>
                                <input type="text" className="input-control m-0 w-100" onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-4">
                                <label className="text-light mb-1">Password</label>
                                <input type="password" className="input-control m-0 w-100" onChange={e => setPassword(e.target.value)} />
                            </div>
                            <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-4 mt-4">
                                <button className="yellowBtn w-100 border-0 f-semibold"
                                    onClick={
                                        submitHandler
                                    }
                                >Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdminLanding