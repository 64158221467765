import React, { useState, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { Dropdown, Nav, NavDropdown, MenuItem, NavLink } from 'react-bootstrap'
import { BrowserRouter as Router, Link, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/actionCreators/userActions';
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import { Helmet } from "react-helmet";


const Header = props => {
    let history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [meta, setMeta] = useState(null)
    const userLogin = useSelector((state) => state.userLogin)

    const dispatch = useDispatch()
    const { loading, error, UserInfo } = userLogin

    const logoutHandler = () => {
        dispatch(logout())
    }
    const musicupload = () => {
        if (UserInfo != null) {

            history.push("/musicupload")
        }
        else {
            props.handleShow()
        }

    }

    const stickerUpload = () => {
        if (UserInfo != null) {
            history.push("/stickerUpload")
        }
        else {
            props.handleShow()
        }

    }

    useEffect(() => {
        setIsLoading(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        axios.post(
            baseUrl + 'api/web/getmeta',
            {

            },
            config
        ).then(res => {


            if (res.data.meta != null) {

                setMeta(res.data.meta)

               
            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }, [])
   
    const matcher = window.matchMedia("(prefers-color-scheme: dark)");
    console.log(matcher.matches)
    var favIcon="fav1.ico";
    if(matcher.matches){
        favIcon="fav2.ico";
    }
    else{
        favIcon="fav1.ico";
    }
    return (
        <div className='YOYO Application'>
            {isLoading ? <h1>Loading</h1> : <><Helmet>
                <meta charSet="utf-8" />
                <title>{meta != null ? meta['siteName'] : "YOYO"}</title>
                
                <link rel="icon" href={favIcon} />
                <meta name="description" content={meta != null ? meta['metaDesc'] : "Yoyo"}></meta>
                <meta name="keywords" content={meta != null ? meta['metaKey'] : "Yoyo"}></meta>

                <meta property="og:title" content={meta != null ? meta['metaTitle'] : "Yoyo"}></meta>
                <meta property="og:url" content={meta != null ? meta['serverUrl'] : "https://www.yoyosinger.com"}></meta>

                <meta property="og:description" content={meta != null ? meta['metaDesc'] : "Yoyo"}></meta>
                <meta property="og:image" content={meta != null ? `${meta['serverUrl']}${meta['logoFolder']}${meta['logo']}` : "logo"}></meta>
            </Helmet>

                <div className="homePageBase container-fluid col-12">
                    <div className="homePageInner col-12 nopadd h-100">
                        <div className="row m-0 headBorder">
                            <div className=" col">
                                <div className=" d-flex flex-row">
                                    <div className="logo mt-3 me-5 mb-3  d-inline-flex">
                                        <Link to="/"><img src={meta != null ? `${meta['serverUrl']}${meta['logoFolder']}${meta['logo']}` : "logo"} alt="Logo" /></Link>
                                    </div>
                                    {/* <div className="uploadBtnBase ">
                                        <button className='yellowBtn border-0 my-4 f-semibold' onClick={musicupload}> Upload Song </button>
                                    </div> */}
                                </div>

                            </div>

                            {UserInfo == null ? <div className="col">
                                <div className="loginBtn  text-end">
                                    <a href="#" onClick={props.handleShow} className="yellowBtn f-semibold my-4"><span className="keyIcon"></span> Login</a>
                                </div>
                            </div>
                                :

                                <div className="col">
                                    <div className="loginBtn  text-end mt-4">
                                        <div className="dropdown pe-4">
                                            <span className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={UserInfo['serverUrl'] + '' + UserInfo['profilePicDir'] + '' + UserInfo['profilePic']} width='50' height='50' alt='profilepic' />
                                            </span>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" onClick={musicupload} >Song Upload</a></li>
                                                <li><a className="dropdown-item" href={'/mysongs'}>Song List</a></li>
                                                <li><a className="dropdown-item" onClick={() => logoutHandler()} >Logout</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                            }
                        </div>
                    </div>
                </div></>}
        </div>
    )
};

export default Header