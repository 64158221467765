import React, { useState } from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

import GoogleLogin from 'react-google-login';
import { login, registerUser, checkexistingemail } from '../../store/actionCreators/userActions';
import { useDispatch, useSelector } from 'react-redux';



const LoginModal = props => {
  /* variable for login */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);


  /* variables for signupform */
  const [signUpEmail, setSignUpEmail] = useState('');
  const [SignUpPassword, setSignUpPassword] = useState('');
  const [SignUpRePassword, setSignUpRePassword] = useState('');
  const [signUpDisplay, setSignUpDisplay] = useState('');

/* variable for error validation form */
  const [signUpEmailErr, setSignUpEmailErr] = useState('');
  const [SignUpPasswordErr, setSignUpPasswordErr] = useState('');
  const [SignUpRePasswordErr, setSignUpRePasswordErr] = useState('');
  const [signUpDisplayErr, setSignUpDisplayErr] = useState('');



  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const userRegister = useSelector((state) => state.userRegister)

  const { loading, error, UserInfo, emailcheckloading, emailcheckerror } = userLogin
  const { regLoading, regError, regUserInfo } = userRegister


  const responseGoogle = (response) => {
    console.log(response);
  }
  
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
    
    if (error == "" || error == null) {
      props.handleClose()
    }

  }

  const emailValidate = (e) => {
    if (signUpEmail === "" || signUpEmail === null || !/.+@.+\.[A-Za-z]+$/.test(signUpEmail)) {
      setSignUpEmailErr("Enter  valid Email Id")
    }
    else {
      setSignUpEmailErr("")
      dispatch(checkexistingemail(signUpEmail))
      if (emailcheckerror != "" || emailcheckerror != null) {
        setSignUpEmailErr(emailcheckerror)
      }
    }
  }
  const nameValidate = (e) => {
    if (signUpDisplay === "" || signUpDisplay == null) {
      setSignUpDisplayErr("Enter the Display name")
    }
    else {
      setSignUpDisplayErr("")
    }
  }
  const passwordValidate = (e) => {

    if (SignUpPassword === "" || SignUpPassword === null) {
      setSignUpPasswordErr("Enter the password")
    }
    else {
      setSignUpPasswordErr("")
    }
    if (SignUpRePassword === "" || SignUpRePassword === null) {
      setSignUpRePasswordErr("Enter the conform password")
    }
    else {
      setSignUpRePasswordErr("")
    }
    if (SignUpPassword !== SignUpRePassword) {
      setSignUpRePasswordErr("Password Miss Match")

    }


  }
  const SignUpsubmitHandler = (e) => {
    e.preventDefault()
    emailValidate()
    nameValidate()
    passwordValidate()

    if (signUpEmailErr !== null && signUpEmailErr !== " " && SignUpPasswordErr !== null && SignUpPasswordErr !== " " && SignUpRePasswordErr !== null && SignUpRePasswordErr !== " " && signUpDisplayErr != null && signUpDisplayErr != " ") {

      dispatch(registerUser(signUpEmail, SignUpPassword, signUpDisplay))
      if (regError == "" || regError == null) {
        props.handleClose()
      }
    }
    else {

    }
  }
  const handleLogin = async googleData => {
    const res = await fetch("/api/v1/auth/google", {
      method: "POST",
      body: JSON.stringify({
        token: googleData.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()
    console.log(data)
    // store returned user somehow
  }

  function handelEmailChange(e) {
    setSignUpEmail(e.target.value)
    emailValidate()
  }

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton onHide={props.handleClose}>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-5">

        {!showSignUp ? <div className="signInBase">
          <Form onSubmit={submitHandler}>
            <div className="sign_in_inputs" >
              <span class="error">{error}
              </span>
              <input type="email" name="login_email" value={email}
                onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" className="name_input" />
              <input type="password" value={password}
                onChange={(e) => setPassword(e.target.value)} name="login_password" placeholder="Password" />
              <div className="col my-3">
                <button className="yellowBtn btnCustom" type="submit">Login</button>
                {loading && <Spinner animation="grow" />}
              </div>
            </div>
          </Form>
          <div className="text-center my-3">
            <span className="">OR</span>
          </div>

          <div className="col mt-3 text-center">

            <GoogleLogin className="googleBtn btnCustom"
              clientId="503169099548-gi02fmmpjad6p9231rfrndgmlj4vu1jk.apps.googleusercontent.com"
              buttonText="Login with Google"
              onSuccess={handleLogin}
              onFailure={handleLogin}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          <div className="text-center my-3">
            <span className="">OR</span>
          </div>
          <button className="themeBtn btnCustom" onClick={(e) => setShowSignUp(true)} >Sign Up</button>
        </div>
          : <div className="signInBase">
            <Form onSubmit={SignUpsubmitHandler}>
              <div className="sign_in_inputs" >
                <span className="error">{regError}</span>
                <input type="text" name="signup_displayName" value={signUpDisplay} autoComplete="off"
                  onChange={(e) =>
                    setSignUpDisplay(e.target.value)
                  } placeholder="Display Name" className="name_input" /><span className="error">{signUpDisplayErr}</span>
                <input type="email" name="signup_email" value={signUpEmail}
                  onChange={(e) => handelEmailChange(e)} placeholder="Email Address" className="name_input" autoComplete="off" /><span className="error">{signUpEmailErr}</span>
                <input type="password" value={SignUpPassword}
                  onChange={(e) => { setSignUpPassword(e.target.value) }} name="signup_password" placeholder="Password" /><span className="error">{SignUpPasswordErr}</span>
                <input type="password" value={SignUpRePassword}
                  onChange={(e) => setSignUpRePassword(e.target.value)} name="signup_repassword" placeholder="Re-Password" /><span className="error">{SignUpRePasswordErr}</span>
                <div className="col my-3">
                  <button className="yellowBtn btnCustom" type="submit">Register</button>
                  {regLoading && <Spinner animation="grow" />}
                </div>
              </div>
            </Form>


            <button className="themeBtn btnCustom" onClick={(e) => setShowSignUp(false)} >LogIn</button>
          </div>}

      </Modal.Body>
    </Modal>
  )
}

export default LoginModal
