import React, { useCallback, useState, useEffect } from 'react'
import UploadBredcumb from './UploadBredcumb';
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../config/constant';
const MusicDetails = () => {
        let history = useHistory()
        const userLogin = useSelector((state) => state.userLogin)
        const { UserInfo } = userLogin
        const [coverImagePreview, setCoverImagePreview] = useState(null)
        const [coverImage, setCoverImage] = useState(null)
        const [coverImageErr, setCoverImageErr] = useState("")
        const [songTitle, setSongTitle] = useState("")
        const [songArtist, setSongArtist] = useState("")
        const [songLanguage, setSongLanguage] = useState("TAMIL")
        const [languageList, setLanguageList] = useState([])
        const [songTags, setSongTags] = useState("")
        const [songTagArray, setSongTagArray] = useState([])
        const [songAgree, setSongAgree] = useState(false)
        const [isValidated, setIsValidated] = useState(false)
        const [loading, setLoading] = useState(false)
        useEffect(() => {
                setLoading(true)
                const formDatas = new FormData()
                formDatas.append("userId", UserInfo._id)

                const userId = UserInfo._id
                console.log(formDatas)
                var local = JSON.parse(localStorage.getItem("UserInfo"))
                var t1 = local['token']

                const config = {
                        headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${t1}`,
                        },
                }
                axios.post(
                        baseUrl + 'api/web/getuserdata',
                        {
                                userId,
                        },
                        config
                ).then(res => {

                        if (res.data.songDraft != null && res.data.songDraft.isDrafted) {
                                if (res.data.songDraft.coverDest != undefined && res.data.songDraft.coverDest != null) {
                                        setCoverImagePreview(res.data.songDraft.coverDest + "" + res.data.songDraft.coverPic)
                                        setSongAgree(true)
                                        setIsValidated(true)
                                }
                                setSongTitle(res.data.songDraft.songTitle)
                                setSongArtist(res.data.songDraft.songArtist)
                                setSongLanguage(res.data.songDraft.songLanguage)
                                setLanguageList(res.data.songLanguage)
                                setSongTags(res.data.songDraft.songTagArray)
                                if (res.data.songDraft.songTagArray != undefined) {
                                        setSongTagArray(res.data.songDraft.songTagArray)
                                }


                        }

                }).catch(err => {
                        console.log(err)
                })

                setLoading(false)

        }, [UserInfo]);

        const onDrop = useCallback(acceptedFiles => {
                console.log(acceptedFiles[0])
                const f = acceptedFiles[0];

                if (f != null && f !== "undefined") {
                        setCoverImageErr("")
                        setCoverImage(f)
                        setCoverImagePreview(URL.createObjectURL(f))

                }
                else {
                        setCoverImage(null)
                        setCoverImageErr("File not supported")
                }

        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'image/jpg, image/jpeg,image/png' })
        return (<>
                <UploadBredcumb activeStep={2} />
                <div className="container">
                        <div className="text-center my-5 songUpload">
                                <h1>Enter Song Details</h1>
                                <div className="col songUploadInner py-4">
                                        <div className="upload-container text-center">
                                                <div className="col" {...getRootProps()}>
                                                        <label htmlFor="upload-photo" className="songUpload"><img src={(coverImagePreview == null || coverImagePreview == " ") ? "images/music.png" : coverImagePreview} alt="Upload" /></label>
                                                        <input /* type="file" name="photo" id="upload-photo"  */ {...getInputProps({ className: 'dropzone' })} />
                                                        {
                                                                isDragActive ?
                                                                        <p className='f-bold mt-3'>Drop the files here ...</p> :
                                                                        <p className='f-bold mt-3'>Upload Album Art</p>
                                                        }
                                                </div>
                                                <span className="error">
                                                        {coverImageErr}
                                                </span>

                                                <div className="form-floating text-start mt-5 mb-3">
                                                        <input type="text" className="form-control" id="title" placeholder="Title"
                                                                value={songTitle}
                                                                onChange={(e) => setSongTitle(e.target.value)} />
                                                        <label htmlFor="title">Title</label>
                                                </div>
                                                <div className="form-floating text-start mb-3">
                                                        <input type="text" className="form-control" id="artist" placeholder="Artist"
                                                                value={songArtist}
                                                                onChange={(e) => setSongArtist(e.target.value)}
                                                        />
                                                        <label htmlFor="artist">Artist</label>
                                                </div>
                                                <div className="form-floating text-start mb-3">                                                       
                                                        <select
                                                                className='select-control border p-3'
                                                                value={songLanguage}
                                                                onChange={(e) => {
                                                                        setSongLanguage(e.target.value)
                                                                }}
                                                        >
                                                                {
                                                                        (() => {
                                                                                let container = [];
                                                                                if (languageList != null)
                                                                                        languageList.forEach((val) => {                                                                                               
                                                                                                        container.push(
                                                                                                                <option value={val}>{val}</option>)
                                                                                        });
                                                                                return container;
                                                                        })()
                                                                }
                                                        </select>                                                       
                                                </div>
                                                <div className="form-floating text-start mb-3">
                                                        <input type="text" className="form-control" id="tags" placeholder="Tags"
                                                                value={songTags}
                                                                onChange={(e) => {
                                                                        const data = e.target.value.split(",")
                                                                        console.log(data)
                                                                        setSongTagArray(data)
                                                                        setSongTags(e.target.value)
                                                                }}
                                                        />
                                                        <label htmlFor="tags">Tags</label>
                                                </div>
                                                <div className="form-floating text-start mb-3">
                                                        {songTagArray.map(name => (
                                                                <span key={name} className="tagColor me-2" onClick={(e) => {
                                                                        var data = songTagArray.filter(function (element) {
                                                                                return element != name;
                                                                        });
                                                                        console.log(data)
                                                                        setSongTagArray(data)
                                                                        var jointags = data.join()
                                                                        setSongTags(jointags)
                                                                }}>
                                                                        {name}
                                                                </span>
                                                        ))}


                                                </div>
                                                <div className="normal_custom_check text-start">
                                                        <label className="control control--checkbox">
                                                                <input type="checkbox"
                                                                        // defaultChecked={songAgree}
                                                                        checked={songAgree}
                                                                        /* checked={songAgree} */
                                                                        onChange={(e) => {
                                                                                console.log.apply(e.target.checked)
                                                                                setSongAgree(e.target.checked.toString())
                                                                        }} />
                                                                I agree that song complies with YoYo Singer terms and service and does not infringe else's right
                                                                <div className="control__indicator"></div>
                                                        </label>
                                                </div>

                                        </div>
                                        <div className="clearfix"></div>

                                </div>
                                <div className=" pt-3 d-flex flex-row justify-content-between ">
                                        <Link to="/musicupload" className="submitBtn float-start"> Back </Link>

                                        {!loading ?
                                                <div /* to="/lyricsupload" */ className="submitBtn float-end cursor-pointer"
                                                        onClick={(e) => {

                                                                if (coverImage != null && songTitle != "" && songArtist != "" && songAgree) {
                                                                        var formData = new FormData()
                                                                        if(songLanguage=="undefined" || songLanguage == undefined ){
                                                                               setSongLanguage("TAMIL"); 
                                                                        }
                                                                        formData.append("songTitle", songTitle)
                                                                        formData.append("songArtist", songArtist)
                                                                        formData.append("songLanguage", songLanguage=="undefined"||songLanguage==undefined?'TAMIL':songLanguage)
                                                                        formData.append("userId", UserInfo._id)
                                                                        formData.append("isValidated", isValidated)
                                                                        formData.append("file", coverImage)
                                                                        songTagArray.forEach((item) => formData.append("songTagArray[]", item))
                                                                        var local = JSON.parse(localStorage.getItem("UserInfo"))
                                                                        var t1 = local['token']
                                                                        const config = {
                                                                                headers: {
                                                                                        'Content-Type': 'application/json',
                                                                                        "Authorization": `Bearer ${t1}`,
                                                                                },
                                                                        }
                                                                        axios.post(baseUrl + "api/web/musicdetailsupload", formData, config).then(res => {
                                                                                setLoading(false)
                                                                                console.log(res.data)
                                                                                if (res.data.status == 1) {
                                                                                        setIsValidated(true)
                                                                                        history.push('lyricsupload')
                                                                                }
                                                                                else {

                                                                                }
                                                                        }).catch(err => {
                                                                                setLoading(false)
                                                                                setCoverImageErr("Failed to upload")
                                                                        })
                                                                }
                                                                else if (isValidated && songTitle != "" && songArtist !== "" && songAgree) {
                                                                        var userId = UserInfo._id
                                                                        var local = JSON.parse(localStorage.getItem("UserInfo"))
                                                                        var t1 = local['token']
                                                                        const config = {
                                                                                headers: {
                                                                                        'Content-Type': 'application/json',
                                                                                        "Authorization": `Bearer ${t1}`,
                                                                                },
                                                                        }
                                                                        axios.post(baseUrl + "api/web/musicdetails", { userId, songTitle, songArtist, songLanguage, songTagArray, isValidated }, config).then(res => {
                                                                                setLoading(false)
                                                                                if (res.data.status == 1) {
                                                                                        setIsValidated(true)
                                                                                        history.push('lyricsupload')
                                                                                }
                                                                                else {

                                                                                }
                                                                        }).catch(err => {
                                                                                setLoading(false)
                                                                                setCoverImageErr("Failed to upload")

                                                                        })
                                                                }
                                                                else {
                                                                        setCoverImageErr("fill the manditory field")
                                                                }

                                                        }}>Next </div> : <Spinner animation="grow" />}
                                </div>
                                <p className="mt-5 text-start"><sup>*</sup>  By uploading this song, you confirm that you are complying with all legal guidelines set forth by our Community Guidelines and Terms of Service.If you have any questions, please refer to our FAQ or contact YoYo Singer for more assistance.</p>
                        </div>
                </div></>
        )
}

export default MusicDetails
