import React, { useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import useState from 'react-usestateref'
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Spinner } from 'react-bootstrap'

const AdminReportedUsers = () => {
    const [userList, setUserList] = useState([])
    const [serverUrl, setServerUrl] = useState("")
    const [profilePicUrl, setProfilePicUrl] = useState("")
    const [coverPicUrl, setCoverPicUrl] = useState("")
    const [dataLoad, setDataLoad] = useState(false)
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [reportId, setReportId] = useState("")
    const [reportedUserId, setReportedUserId] = useState("")
    const [method, setMethod] = useState("")
    const [isChecked, setIsChecked] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [actionType, setActionType] = useState(false)
    const [selectedActionType, setSelectedActionType] = useState("freeze")
    const [errorMsg, setErrorMsg] = useState("")
    const [reportList, setReportList, useRefReportList] = useState([])
    const [adminSetting, setAdminSetting] = useState([])
    const [userReportList, setUserReportList] = useState([])
    const [reportTable, setReportTable] = useState([])
    const [selectReason, setSelectReason, useRefSelectReason] = useState([])
    const [selectedId, setSelectedId, useRefSelectedId] = useState([])
    const [reportReason, setReportReason, useRefReportReason] = useState([])


    useEffect(() => {

        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        axios.post(
            baseUrl + 'api/web/getreporteduserslist',
            {

            },
            config
        ).then(res => {
            if (res.data.userList != null) {
                setUserList(res.data.userList)
                setServerUrl(res.data.serverUrl)
                setCoverPicUrl(res.data.coverPicUrl)
                setProfilePicUrl(res.data.profilePicUrl)
                var data = [];


                userList.map((listValue, index) => {
                    data.push({
                        col1: index + 1,
                        col2: listValue.user.userName,
                        col3: listValue.user.gender,
                        col4: <img src={serverUrl + profilePicUrl + listValue.user.profilePic} width="80" />,
                        col5: <img src={serverUrl + coverPicUrl + listValue.user.coverPic} width="80" />,
                        col6: listValue.user.email,
                        col7: listValue.reportCount,
                        col8: <Button className="btn btn-primary f-regular"
                            onClick={(e) => {
                                setReportedUserId(listValue.user._id)
                                setShowForm(true)
                                setIsLoading(true)
                                setErrorMsg("")
                                e.preventDefault();
                                var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                var t1 = local['token']
                        
                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "Authorization": `Bearer ${t1}`,
                                    },
                                }
                                var isFrozen = actionType


                                axios.post(baseUrl + "api/web/getreportslist", { "reportedUserId": listValue.user._id }, config).then(res => {


                                    if (res.data.status == 1) {

                                        setServerUrl(res.data.serverUrl)
                                        setCoverPicUrl(res.data.coverPicUrl)
                                        setProfilePicUrl(res.data.profilePicUrl)
                                        setReportList(res.data.reportList)
                                        setUserReportList(res.data.adminSetting[0].userReportList)
                                        setErrorMsg("")
                                        setIsLoading(false)



                                        var reportData = [];

                                        useRefReportList.current.map((reportLists, index) => {

                                            reportData.push({
                                                col1: index + 1,
                                                col2: reportLists.reason,
                                                col3: reportLists.reportedBy.userName,
                                                col4: <div class="normal-custom_check ">
                                                    <label class="control control--checkbox">
                                                        <input type="checkbox" value={reportLists._id} onChange={onSelectedUser} checked={useRefSelectedId.current.includes(reportLists._id)} />
                                                        <div class="control__indicator"></div>
                                                    </label>
                                                </div>,
                                            })
                                        }
                                        )
                                        setReportTable(reportData);


                                    }
                                    else {
                                        setIsLoading(false)
                                        setErrorMsg(res.data.message)
                                    }
                                }).catch(err => {
                                    setIsLoading(false)
                                    setErrorMsg("Something Went Wrong")
                                })
                            }}
                        >View Reports</Button>,



                    });

                });



                setTableData(data)
                setDataLoad(true)

            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })

    }, [dataLoad]);

    const onSelectedOptions = (e) => {
        if (selectReason.includes(e.target.value)) {
            const selectedReasondSet = new Set([...selectReason]);
            selectedReasondSet.delete(e.target.value)
            setSelectReason([...selectedReasondSet]);
        }
        else {
            setSelectReason([...selectReason, e.target.value]);
        }

        var reportData = [];
        var userIds = [];
        setSelectedId([]);

        useRefReportList.current.map((reportLists, index) => {
            if (useRefSelectReason.current.includes(reportLists.reason)) {
                setSelectedId([...useRefSelectedId.current, reportLists._id])
                setReportReason([...useRefReportReason.current,reportLists.reason])
            }
            else{
                
                const useRefReportReasonSet = new Set([...useRefReportReason.current]);
                useRefReportReasonSet.delete(reportLists.reason)
                setReportReason([...useRefReportReasonSet])
                }
            reportData.push({
                col1: index + 1,
                col2: reportLists.reason,
                col3: reportLists.reportedBy.userName,
                col4: <div class="normal-custom_check ">
                    <label class="control control--checkbox">
                        <input type="checkbox" value={reportLists._id} onChange={onSelectedUser} checked={useRefSelectedId.current.includes(reportLists._id)} />
                        <div class="control__indicator"></div>
                    </label>
                </div>,
            })
        }
        )
        console.log(useRefReportReason.current)

        setReportTable(reportData);
    };

    const onSelectedUser = (e) => {
        console.log(e.target.value)
        var reportData = [];
        if (useRefSelectedId.current.includes(e.target.value)) {
            
            const selectedUserdSet = new Set([...useRefSelectedId.current]);
            selectedUserdSet.delete(e.target.value)
            setSelectedId([...selectedUserdSet])

        }
        else {
           
            setSelectedId([...useRefSelectedId.current, e.target.value])
        }
        useRefReportList.current.map((reportLists, index) => {
            if ((useRefSelectedId.current.includes(e.target.value))){
                setReportReason([...useRefReportReason.current,reportLists.reason])
            }
            else{
                
            const useRefReportReasonSet = new Set([...useRefReportReason.current]);
            useRefReportReasonSet.delete(reportLists.reason)
            setReportReason([...useRefReportReasonSet])
            }
            reportData.push({
                col1: index + 1,
                col2: reportLists.reason,
                col3: reportLists.reportedBy.userName,
                col4: <div class="normal-custom_check ">
                    <label class="control control--checkbox">
                        <input type="checkbox" value={reportLists._id} onChange={onSelectedUser} checked={useRefSelectedId.current.includes(reportLists._id)} />
                        <div class="control__indicator"></div>
                    </label>
                </div>,
            })
        }
        )
        console.log(useRefReportReason.current)

        setReportTable(reportData);
    };
    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'col2',
            },
            {
                Header: 'Gender',
                accessor: 'col3',
            },
            {
                Header: 'Photo',
                accessor: 'col4',
            },
            {
                Header: 'Cover Photo',
                accessor: 'col5',
            },
            {
                Header: 'EmailID',
                accessor: 'col6',
            },
            {
                Header: 'Report Count',
                accessor: 'col7',
            },
            {
                Header: 'Action',
                accessor: 'col8',
            },


        ],
        [tableData]
    )
    const columns1 = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Reason',
                accessor: 'col2',
            },
            {
                Header: 'Reported By',
                accessor: 'col3',
            },
            {
                Header: 'Action',
                accessor: 'col4',
            },

        ],
        [reportTable]
    )
    var data1 = React.useMemo(
        () => tableData,
        [tableData]
    )
    var reportData1 = React.useMemo(
        () => reportTable,
        [reportTable]
    )
    var element = ""

    console.log(reportTable)

    return (
        <div className="col  ">
            <div className="container ">
                {!showForm && <div className="">
                    <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <h3 className="f-semibold m-0">Total No of Users</h3>
                            <h3 className="f-bold ps-5 m-0">{tableData.length}</h3>
                        </div>
                    </div>
                    <div className="table-responsive adminTable ">
                        {isLoading ?
                            <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                                <Spinner animation="grow" />
                            </div>
                            :
                            <DataTable columns={columns} data={data1} />}
                    </div>

                </div>}

                {
                    isLoading && showForm ?
                        <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                            <Spinner animation="grow" />
                        </div>
                        : showForm &&
                        <div>
                        <div className='mt-5'>
                        <button className="adminThemebtn f-regular"  onClick={(e) => { setShowForm(false) }} >Back</button>
                        </div>
                        <div className='col-lg-12 col-sm-12 col-md-12 col-xxl-12 col-xl-12 col-12 admin-profile-base bg-white mt-4 mb-5'>
                            <div className='admin-profile-banner position-relative col-12'>
                                <img src={serverUrl + coverPicUrl + useRefReportList.current[0].user.coverPic} className='w-100' />
                                <div className='admin-profilepic position-absolute'>
                                    <img src={serverUrl + profilePicUrl + useRefReportList.current[0].user.profilePic} />
                                </div>
                            </div>
                            <div className='group-of-actions col-12 p-4'>
                                <h4 className="f-semibold">Actions</h4>
                                <ul className='list-inline mt-4'>
                                    {userReportList.map((obj, key) => {
                                        return <li className='list-inline-item col-lg-4 col-md-4 col-12 m-0'>
                                            <div class="normal-custom_check ">
                                                <label class="control control--checkbox">
                                                    <input type="checkbox" checked={useRefSelectReason.current.includes(obj)} onChange={onSelectedOptions} value={obj} />{obj}
                                                    <div class="control__indicator"></div>
                                                </label>
                                            </div>
                                        </li>
                                    })}
                                </ul>
                            </div>
                            <div className="datatable-reports p-4">
                                {isLoading ?
                                    <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                                        <Spinner animation="grow" />
                                    </div>
                                    :
                                    <DataTable columns={columns1} data={reportData1} />}
                                    {useRefSelectedId.current.length > 0 &&
                                    <div className='datatable-btns mt-5 justify-content-between d-flex'>
                                        <Button className="btn btn-primary f-regular"
                                                onClick={(e) => {
                                                    setErrorMsg("")
                                                    e.preventDefault();
                                                    var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                                    var t1 = local['token']
                                            
                                                    const config = {
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            "Authorization": `Bearer ${t1}`,
                                                        },
                                                    }
                                                    var reportIds = useRefSelectedId.current
                                                    const selectedActionType ="fake"
                                                    var reportedUserId = useRefReportList.current[0].user._id
                                                   
                                                    console.log(reportReason)
                    
                                                    axios.post(baseUrl + "api/web/setreporteduserslist", { reportIds,selectedActionType,reportedUserId }, config).then(res => {
                    
                    
                                                        if (res.data.status == 1) {
                                                                setShowForm(false)
                                                                 setDataLoad(!dataLoad)
                                                                 setSelectReason("")
                                                                 setSelectedId("")
                                                                 setReportReason("")
                                                        }
                                                        else {
                                                            setIsLoading(false)
                                                            setErrorMsg(res.data.message)
                                                        }
                                                    }).catch(err => {
                                                        setIsLoading(false)
                                                        setErrorMsg("Something Went Wrong")
                                                    })
                                                }}
                                        >Fake Reports</Button>
                                        <Button className="btn btn-danger f-regular"
                                         onClick={(e) => {
                                            setErrorMsg("")
                                            e.preventDefault();
                                            var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                            var t1 = local['token']
                                    
                                            const config = {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    "Authorization": `Bearer ${t1}`,
                                                },
                                            }
                                            var reportIds = useRefSelectedId.current
                                            const selectedActionType ="photoviolation"
                                            var reportedUserId = useRefReportList.current[0].user._id
                                           
                                            console.log(reportReason)
            
                                            axios.post(baseUrl + "api/web/setreporteduserslist", { reportIds,selectedActionType,reportedUserId }, config).then(res => {
            
            
                                                if (res.data.status == 1) {
                                                        setShowForm(false)
                                                         setDataLoad(!dataLoad)
                                                         setSelectReason("")
                                                          setSelectedId("")
                                                        setReportReason("")
                                                }
                                                else {
                                                    setIsLoading(false)
                                                    setErrorMsg(res.data.message)
                                                }
                                            }).catch(err => {
                                                setIsLoading(false)
                                                setErrorMsg("Something Went Wrong")
                                            })
                                        }}
                                        >Image Delete and Freeze</Button>
                                        <Button className="btn btn-danger f-regular"
                                        onClick={(e) => {
                                            setErrorMsg("")
                                            e.preventDefault();
                                            var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                            var t1 = local['token']
                                    
                                            const config = {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    "Authorization": `Bearer ${t1}`,
                                                },
                                            }
                                            var reportIds = useRefSelectedId.current
                                            const selectedActionType ="freeze"
                                            var reportedUserId = useRefReportList.current[0].user._id
                                           
                                            console.log(reportReason)
            
                                            axios.post(baseUrl + "api/web/setreporteduserslist", { reportIds,selectedActionType,reportedUserId }, config).then(res => {
            
            
                                                if (res.data.status == 1) {
                                                        setShowForm(false)
                                                         setDataLoad(!dataLoad)
                                                         setSelectReason("")
                                                        setSelectedId("")
                                                         setReportReason("")
                                                }
                                                else {
                                                    setIsLoading(false)
                                                    setErrorMsg(res.data.message)
                                                }
                                            }).catch(err => {
                                                setIsLoading(false)
                                                setErrorMsg("Something Went Wrong")
                                            })
                                        }}
                                        >Freeze</Button>
                                    </div>
                                    }
                            </div>

                        </div>
                        </div>
                }
            </div>

        </div>

    )
}

export default AdminReportedUsers
