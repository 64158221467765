export const baseUrl="https://yoyosinger.com/"
export const serverUrl="https://yoyosinger.com/"
export const androidAppLink="https://yoyosinger.com/"

/* export const baseUrl="http://192.168.0.109:5000/"
export const serverUrl="http://192.168.0.109:5000/"
export const androidAppLink="http://192.109.0.102/"
 */
export const iosAppLink="https://yoyosinger.com/"
export const profilePicDir="uploads/profilePic/"
export const strickerUrl="uploads/sticker/"
export const strickerCatUrl="uploads/stickerCategory/"
export const coverPicDir="uploads/coverPic/"
export const r_key_id="rzp_test_s1IOHVXK96IJHN" //razerPay
export const r_key_secret="ifDqRmvALu8efxQLuANF9A2N" //razerPay
export const one_signal_rest_api_key="MDJiNTEzZGItMmNkNi00ZGM2LThiODQtMzE0MTRkZGRmMWUy" //one signal
export const one_signal_app_id="1cdc2c73-a5b6-4ca5-a552-0dc901bd1c4c" //one signal

export const voteLimit=150

/* "proxy":"http://127.0.0.1:5000", */