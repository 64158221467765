


import 'bootstrap/dist/js/bootstrap.min.js';
import './css/style.scss';
import Header from './web/common/Header';
import Landing from './web/landing/Landing';
import Footer from './web/common/Footer';

import './App.css';
import { useSelector, useDispatch } from "react-redux"

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React, {useState,useRef} from 'react'
import MusicUpload from './web/songupload/MusicUpload';


import MusicDetails from './web/songupload/MusicDetails';
import LyricsUpload from './web/songupload/LyricsUpload';
import LyricsTiming from './web/songupload/LyricsTiming';


import AdminLanding from './admin/AdminLanding/AdminLanding';
import AdminSidebar from './admin/adminDashboard/AdminSidebar';

import UserLanding from './web/user/UserLanding';
import Player from './web/user/Player';
import Privacy from './web/pages/Privacy';
import RedirectToApp from './web/common/RedirectToApp';


function App() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Router pathname="/">
      <div className="app">

        {(!window.location.pathname.includes('/admin')) && <Header handleShow={() => handleShow()} handleClose={() => handleClose} ></Header>}


        <Switch>
          <Route exact path="/app" component={RedirectToApp } />
          <Route exact path="/" component={Landing} />
          <Route exact path="/musicupload" component={MusicUpload} />
          <Route path="/musicdetails" component={MusicDetails} exact />
          <Route path="/lyricsupload" component={LyricsUpload} exact />
          <Route path="/lyricstiming" component={LyricsTiming} exact />
          <Route exact path="/admin" component={AdminLanding} />
          <Route exact path="/mysongs" component={UserLanding} />
          <Route exact path="/admin/dashboard" component={AdminSidebar} />
          <Route exact path="/:invite/:songRef" component={Player} />
          <Route exact path="/terms-of-use" component={() => <Privacy pageSlug={"termsOfUse"} />} />
          <Route exact path="/privacy-policy" component={() => <Privacy pageSlug={"privacyPolicy"} />} />
          <Route exact path="/disclamier" component={() => <Privacy pageSlug={"disclaimer"} />} />
          <Route exact path="/about-us" component={() => <Privacy pageSlug={"aboutUs"} />} />
          <Route exact path="/privacy" component={() => <Privacy pageSlug={"privacyPolicy"} />} />


          {/*  <Landing/> */}
        </Switch>
        {(!window.location.pathname.includes('/admin')) && <Footer show={show} handleClose={() => handleClose()}></Footer>}
      </div>
    </Router>

  );
}

export default App;
