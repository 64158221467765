import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button } from 'react-bootstrap'

const Stickers = () => {
    const [catName, setCatName] = useState("")
    const [showForm, setShowForm] = useState(false)
    const [stickerName, setStickerName] = useState("")
    const [stickerPrice, setStickerPrice] = useState("")
    const [stickerId, setStickerId] = useState("")
    const [coverImage, setCoverImage] = useState(null)
    const [coverImagePreview, setCoverImagePreview] = useState(null)
    const [isChecked, setIsChecked] = useState(true)
    const [coverImageErr, setCoverImageErr] = useState("")
    const [stickerData, setstickerData] = useState([])
    const [tabelData, settabelData] = useState([])
    const [dataLoad, setdataLoad] = useState(false)
    const [StickerPicHost, setStickerPicHost] = useState("")
    const [show, setShow] = useState(false);
    const [stickerCatData, setStickerCatData] = useState([])
    const [deleteId, setDeleteId] = useState("");
    const [selectedCatId, setSelectedCatId] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {

        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }

        axios.post(
            baseUrl + 'api/getStickers',
            {

            },
            config
        ).then(res => {



            if (res.data.stickerData != null) {
                setstickerData(res.data.stickerData)
                setStickerPicHost(res.data.StickerPicHost)
                var data = [];
                stickerData.map((listValue, index) => {
                    data.push({
                        col1: index + 1,
                        col2: listValue.categoryId.stickerCat,
                        col3: listValue.stickerName,
                        col4: listValue.stickerPrice,
                        col5: <img src={StickerPicHost + listValue.stickerUrl} width="80" />,
                        col6: listValue.stickerStatus ? <span className="badge bg-success f-light">Active</span> : <span className="badge bg-secondary f-light">InActive</span>,
                        col7: <ul className="list-inline m-0">
                            <li className="list-inline-item pe-2 pe-auto" onClick={(e) => {
                                setStickerId(listValue._id)
                                setSelectedCatId(listValue.categoryId._id)
                                setStickerName(listValue.stickerName)
                                 setStickerPrice(listValue.stickerPrice)
                                setCoverImagePreview(StickerPicHost + listValue.stickerUrl)
                                setIsChecked(listValue.stickerStatus)
                                setShowForm(true)

                            }} data-toggle="tooltip" data-placement="top" title="Edit"><img src={require("../../images/edit.svg").default} alt="edit" /></li>

                            <li className="list-inline-item"  ><img src={require("../../images/delete.svg").default} alt="delete" onClick={(e) => { setShow(true); setDeleteId(listValue._id) }} /></li>


                        </ul>,
                        col8: listValue.stickerStatus ? "Active" : "InActive",
                        col9:listValue.publish?"published":"unpublished",
                                col10: listValue.publish?<span className="btn btn-success  disabled f-regular" >Published</span>:<span className="btn btn-success   f-regular" 
                                 onClick={(e) => {
                                                    e.preventDefault();
                                                    var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }

                                                   const id=listValue._id
                                                
                                                    axios.post(baseUrl +"api/publishsticker", {id,}, config).then(res => {
                                                       
                                                        console.log(res.data)
                                                        if (res.data.status == 1) {
                                                           
                                                            setStickerPicHost(res.data.StickerPicHost)
                                                            setdataLoad(!dataLoad)
                                                            
                                                        }
                                                        else {
                
                                                        }
                                                    }).catch(err => {
                                                        setCoverImageErr("Failed to upload")
                                                    })
                                            }}
                                 
                                 
                                 >Unpublished</span> ,
                    });

                });
                settabelData(data)
                setdataLoad(true)

            }

        }).catch(err => {
            console.log(err)
        })

    }, [dataLoad]);

    useEffect(() => {
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }

        axios.post(
            baseUrl +'api/getstickercategory',
            {

            },
            config
        ).then(res => {

            if (res.data.stickerCatData != null) {
                setStickerCatData(res.data.stickerCatData)
            }

        }).catch(err => {
            console.log(err)
        })

    }, []);

    const handleOnChange = () => {

        setIsChecked(!isChecked);
        console.log(isChecked)
    };

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles[0])
        const f = acceptedFiles[0];

        if (f != null && f !== "undefined") {
            console.log(f);
            setCoverImageErr("")
            setCoverImage(f)
            setCoverImagePreview(URL.createObjectURL(f))

        }
        else {
            setCoverImage(null)
            setCoverImageErr("File not supported")
        }

    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'image/jpg, image/jpeg,image/png,image/svg+xml,image/gif' })



    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Category Name',
                accessor: 'col2',
            },
            {
                Header: 'Stickers Name',
                accessor: 'col3',
            },
            {
                Header: 'Price',
                accessor: 'col4',
            },
            {
                Header: 'Stickers',
                accessor: 'col5',
            },
            {
                Header: 'Status',
                accessor: 'col6',
            },
            {
                Header: 'Action',
                accessor: 'col7',
            },
            {
                Header: 'Publish',
                accessor: 'col10',
            },
             {
                Header: 'Status',
                accessor: 'col8',
            },
             {
                Header: 'publish',
                accessor: 'col9',
            },
        ],
        [tabelData]
    )

    var data1 = React.useMemo(
        () => tabelData,
        [tabelData]
    )

    return (
        <div className="col  vh-100">
            <div className="container h-100">
                {!showForm && <div className="">
                    <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <h3 className="f-semibold m-0">Total No of Stickers</h3>
                            <h3 className="f-bold ps-5 m-0">{tabelData.length}</h3>
                        </div>
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <button className="adminThemebtn" onClick={(e) => {
                                setShowForm(true)
                                setStickerId("")
                              setSelectedCatId(stickerCatData[0]['_id'])
                              setStickerName("")
                              setStickerPrice("")
                              setIsChecked(true)
                              setCoverImage("")
                              setCoverImagePreview(null)
                                console.log(showForm)
                            }}>Add Stickers</button>
                        </div>
                    </div>
                    <div className="table-responsive adminTable stickerCattable">
                        <DataTable columns={columns} data={data1} />
                    </div>

                </div>}
                {showForm && <div className="input-form col-12 col-lg-6 col-xxl-6 col-xl-6 col-md-8 col-sm-8 bg-white shadow-sm rounded p-4 m-auto mt-5">
                    <form>

                        <input type="hidden" value={stickerId} />
                        <div className="col-12 col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 mb-4">
                            <label className="f-semibold mb-2">Category Name</label>
                            <select className='select-control border'
                                    value={selectedCatId}
                                    onChange={e => setSelectedCatId(e.target.value)}>
                                    {stickerCatData.map(o => (
                                    <option key={o._id} value={o._id}>{o.stickerCat}</option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-12 col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 mb-4">
                            <label className="f-semibold mb-2">Sticker Name</label>
                            <input type="text" className="form-control" value={stickerName}
                                onChange={(e) => setStickerName(e.target.value)} />
                        </div>
                        <div className="col-12 col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 mb-4">
                            <label className="f-semibold mb-2">Sticker Price</label>
                            <input type="number" className="form-control" value={stickerPrice}
                                onChange={(e) => setStickerPrice(e.target.value)} />
                        </div>
                        <div className="col-12 col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 mb-4" {...getRootProps()}>
                            <label className="f-semibold mb-2 w-100">Upload Sticker</label>

                            <label htmlFor="upload-photo" className="IconUpload">
                                <img src={(coverImagePreview == null || coverImagePreview == " ") ? "../images/music.png" : coverImagePreview} alt="Upload" /></label>
                            <input type="file" className="opacity-0" name="photo"/*  id="upload-photo"  */ {...getInputProps({ className: 'dropzone' })} />

                        </div>
                        <div className="col-12 col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 mb-4">
                            <label className="f-semibold mb-2">Status</label>
                            <div class="switch_control">
                                <input id="cmn-toggle-1" class="cmn-toggle cmn-toggle-round save_function_multiple_checkbox" value="" checked={isChecked} onChange={handleOnChange} type="checkbox" />
                                <label htmlFor="cmn-toggle-1"></label>
                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 pt-4 mb-4 d-flex justify-content-between">
                            <button className="adminThemebtn bg-light  text-dark" onClick={(e) => {
                                setShowForm(false)
                                setStickerId("")
                            }}>Cancel</button>
                            <button className="adminThemebtn" onClick={(e) => {
                                e.preventDefault();

                                if (coverImage !== null && stickerName != "" && stickerPrice != "") {
                                    if (stickerId == "") {
                                                   
                                        var formData = new FormData()
                                        formData.append("categoryId", selectedCatId)
                                        formData.append("stickerName", stickerName)
                                        formData.append("stickerPrice", stickerPrice)
                                        formData.append("sfolder", 'sticker')
                                        formData.append("stickerStatus", isChecked)

                                        formData.append("file", coverImage)
                                        console.log(formData);

                                        var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                        var t1 = local['token']
                                
                                        const config = {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                "Authorization": `Bearer ${t1}`,
                                            },
                                        }
                                
                                        axios.post(baseUrl +"api/setstickers", formData,config).then(res => {

                                            console.log(res.data)
                                            if (res.data.status == 1) {
                                                setstickerData(res.data.stickerData)
                                                setStickerPicHost(res.data.StickerPicHost)
                                                setShowForm(false)
                                                setdataLoad(!dataLoad)

                                            }
                                            else {
                                                setCoverImageErr("Failed to upload")
                                            }
                                        }).catch(err => {

                                            setCoverImageErr("Failed to upload")

                                        })
                                    }
                                    else {
                                       
                                       
                                        var formData = new FormData()
                                         formData.append("stickerId", stickerId)
                                         formData.append("categoryId", selectedCatId)
                                         formData.append("stickerName", stickerName)
                                         formData.append("stickerPrice", stickerPrice)
                                         formData.append("sfolder", 'sticker')
                                         formData.append("stickerStatus", isChecked)

                                        formData.append("file", coverImage)
                                        
                                        var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                        var t1 = local['token']
                                
                                        const config = {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                "Authorization": `Bearer ${t1}`,
                                            },
                                        }
                                


                                        axios.post(baseUrl +"api/updatestickerswithtimg", formData,config).then(res => {

                                            if (res.data.status == 1) {
                                               setstickerData(res.data.stickerData)
                                                setStickerPicHost(res.data.StickerPicHost)
                                                setShowForm(false)
                                                setdataLoad(!dataLoad)

                                            }
                                            else {
                                                setCoverImageErr("Failed to upload")
                                            }
                                        }).catch(err => {
                                            setCoverImageErr("Failed to upload")
                                        })
                                    }

                                }
                                else if (coverImage == null && stickerName != "" && stickerId != "") {
                                    var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                    var t1 = local['token']
                            
                                    const config = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            "Authorization": `Bearer ${t1}`,
                                        },
                                    }
                            
                                    var stickerStatus=isChecked
                                    var categoryId=selectedCatId


                                    axios.post(baseUrl +"api/updatestickerswithoutimg", { stickerName, stickerId, stickerStatus,stickerPrice,categoryId }, config).then(res => {

                                        console.log(res.data)
                                        if (res.data.status == 1) {
                                            setstickerData(res.data.stickerData)
                                            setStickerPicHost(res.data.StickerPicHost)
                                            setShowForm(false)
                                            setdataLoad(!dataLoad)
                                        }
                                        else {

                                        }
                                    }).catch(err => {
                                        setCoverImageErr("Failed to upload")
                                    })
                                }

                                else {
                                    console.log("error");
                                    setCoverImageErr("fill the manditory field")
                                }

                            }}>Submit</button>

                        </div>

                    </form>
                </div>}
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete the sticker Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center ">
                        <h2 className="f-bold fs-4">Are you sure want to delete the sticker </h2>
                        <Button variant="danger" className="me-3" onClick={(e) => {
                            e.preventDefault();
                            var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }

                            const sfolder = "sticker"

                            axios.post(baseUrl + "api/deletestickers", { deleteId, sfolder, }, config).then(res => {

                                console.log(res.data)
                                if (res.data.status == 1) {

                                    setStickerPicHost(res.data.StickerPicHost)
                                    setShow(false)
                                    setdataLoad(!dataLoad)

                                }
                                else {

                                }
                            }).catch(err => {
                                setCoverImageErr("Failed to upload")
                            })
                        }}>Delete</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    </div>

                </Modal.Body>

            </Modal>
        </div>

    )
}

export default Stickers
