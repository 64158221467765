import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button ,Spinner} from 'react-bootstrap'

const AdminFeaturedList = () => {
    const [userList, setUserList] = useState([])
    const [serverUrl, setServerUrl] = useState("")
    const [profilePicUrl, setProfilePicUrl] = useState("")
    const [coverPicUrl, setCoverPicUrl] = useState("")
  
    const [dataLoad, setDataLoad] = useState(false)
    const [tableData, setTableData] = useState([])  
    const [isLoading, setIsLoading] = useState(false)    
   

    useEffect(() => {
        console.log("inside use effect")
        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        axios.post(
            baseUrl +'api/web/getfeaturedlist',
            {

            },
            config
        ).then(res => {
            if (res.data.userList != null) {
                setUserList(res.data.userList)
                setServerUrl(res.data.serverUrl)
                setCoverPicUrl(res.data.coverPicUrl)
                setProfilePicUrl(res.data.profilePicUrl)
                var data = [];
               

                userList.map((listValue, index) => {
                    data.push({
                        col1: index + 1,
                        col2: listValue.userName,
                        col3: listValue.gender,
                        col4: <img src={serverUrl+profilePicUrl + listValue.profilePic} width="80" />,
                        col5: <img src={serverUrl+coverPicUrl + listValue.coverPic} width="80" />,
                        col6:  listValue.email,
                        col7: listValue.isEmailVerified ? <span className="badge bg-success f-light">verified</span> : <span className="badge bg-danger f-light">Not verified</span>,
                        col9:  <img src={require("../../images/delete.svg").default} alt="delete"/*  onClick={(e) => { setShow(true); setDeleteId(listValue._id) }}  *//>,
                        col10:  listValue.isEmailVerified ? "verified" : "Not verified",
                        col8:   <span className='cursor-pointer'><img src= { listValue.featured ? require("../../images/star.svg").default:require("../../images/staruncheck.svg").default} alt="featured" width={30} 
                        onClick={(e) => {
                            e.preventDefault();
                            setIsLoading(true)
                            var local = JSON.parse(localStorage.getItem("AdminInfo"))

                            var t1 = local['token']
                    
                            const config = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "Authorization": `Bearer ${t1}`,
                                },
                            }
                           const id=listValue._id
                           var method=!listValue.featured
                        
                            axios.post(baseUrl + "api/web/featuretheuser", {id,method}, config).then(res => {
                               
                                console.log(res.data)
                                if (res.data.status == 1) {
                                   
                                   
                                    setDataLoad(!dataLoad)
                                    
                                }
                                else {
                                    setIsLoading(false)
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                    }}
        
                        /></span>  ,
                    });

                });
                console.log(data)
                setTableData(data)
                setDataLoad(true)

            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })

    }, [dataLoad]);


    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'col2',
            },
            {
                Header: 'Gender',
                accessor: 'col3',
            },
            {
                Header: 'Photo',
                accessor: 'col4',
            },
            {
                Header: 'Cover Photo',
                accessor: 'col5',
            },
            {
                Header: 'EmailID',
                accessor: 'col6',
            },
            {
                Header: 'Featured',
                accessor: 'col8',
            },
            {
                Header: 'Email Status',
                accessor: 'col7',
            },

            {
                Header: 'Action',
                accessor: 'col9',
            },
            {
                Header: 'Email Status',
                accessor: 'col10',
            },
           
        ],
        [tableData]
    )

    var data1 = React.useMemo(
        () => tableData,
        [tableData]
    )

    return (
        <div className="col  vh-100">
            <div className="container h-100">
                {true && <div className="">
                    <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <h3 className="f-semibold m-0">Total No of Users</h3>
                            <h3 className="f-bold ps-5 m-0">{tableData.length}</h3>
                        </div>
                    </div>
                    <div className="table-responsive adminTable stickerCattable">
                    {isLoading ?
                        <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                            <Spinner animation="grow" />
                        </div>
                        :
                        <DataTable columns={columns} data={data1} />}
                    </div>

                </div>}
               
            </div>
            
        </div>

    )
}

export default AdminFeaturedList
