import React,{useEffect,useState } from 'react'

const UploadBredcumb = ({activeStep}) => {
    const [currentState, setCurrentState] = useState(0)
    useEffect(() => {
        
        setCurrentState(activeStep);
        
        
      },[activeStep]);

     // console.log(currentState)
    return ( 
        <div className="stepToUpload text-center">
        <ul className="list-inline stepsList py-3 mb-0">
                <li className={"list-inline-item " +(currentState>=1 ? ' active ':currentState)}><span>1. Song Upload</span></li>
                <li className={"list-inline-item " +(currentState>=2 && ' active ')}><span>2. Song Details</span><span className="animatedLine">&nbsp;</span></li>
                <li className={"list-inline-item " +(currentState>=3 && ' active ')}><span>3. Song Lyrics</span><span className="animatedLine">&nbsp;</span></li>
                <li className={"list-inline-item " +(currentState>=4 && ' active ')}><span>4. Song Timing</span><span className="animatedLine">&nbsp;</span></li>
                <li className={"list-inline-item " +(currentState>=5 && ' active')}><span>5. Song Structure</span><span className="animatedLine">&nbsp;</span></li>
        </ul>
</div>
    )
}

export default UploadBredcumb
