import {React,useEffect} from 'react'

const RedirectToApp = () => {
    useEffect(() => {
        window.addEventListener('DOMContentLoaded', (event) => {
            var userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') > -1) {
                window.location.href = 'https://play.google.com/store/apps/details?id=com.yoyosinger.yoyo';
            } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipod') > -1 || userAgent.indexOf('ipad') > -1) {
                window.location.href = 'https://yoyosinger.com/';
            }
            else{
                window.location.href = 'https://yoyosinger.com/';
                
            }
        });
    }, [])

    return (
        <div>RedirectToApp</div>
    )
}

export default RedirectToApp