import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import {baseUrl} from '../../config/constant'
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button } from 'react-bootstrap'

const ImportFiles = () => {
    const [showForm, setShowForm] = useState(false)
    const [filesData, SetFilesData] = useState("")
    
    const [fileId, setFileId] = useState(null)
    const [filePreview, setFilePreview] = useState("")
    const [file, setFile] = useState("")
    const [tabelData, settabelData] = useState([])
    const [dataLoad, setdataLoad] = useState(false)
    const [stickerCatData, setStickerCatData] = useState([]) 
    useEffect(() => {

      
  var local = JSON.parse(localStorage.getItem("AdminInfo"))

  var t1 = local['token']

  const config = {
      headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${t1}`,
      },
  }
        axios.post(
            baseUrl +'api/web/getallfiles',
            {

            },
            config
        ).then(res => {



            if (res.data.files != null) {
                SetFilesData(res.data.files)
                
                var data = [];
                res.data.files.map((listValue, index) => {
                    data.push({
                        col1: index + 1,
                        col2: <img width="80" src={baseUrl+listValue.destination+"/"+listValue.fileName} alt="Files"  />,
                        col3:  baseUrl+listValue.destination +listValue.fileName,
                        col4: <img src={require("../../images/delete.svg").default} alt="delete" onClick={(e) => {  setFileId(listValue._id) 
                        
                            e.preventDefault();
                           
  var local = JSON.parse(localStorage.getItem("AdminInfo"))

  var t1 = local['token']

  const config = {
      headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${t1}`,
      },
  }
                            const sfolder = "sticker"

                            axios.post(baseUrl +"api/web/deletefile", { fileId:listValue._id, }, config).then(res => {

                                console.log(res.data)
                                if (res.data.status == 1) {
                                    setdataLoad(!dataLoad)

                                }
                                else {

                                }
                            }).catch(err => {
                                
                            })
                        
                        }} />,
                    });

                });
                settabelData(data)
                setdataLoad(true)

            }

        }).catch(err => {
            console.log(err)
        })

    }, [dataLoad]);

   
    

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles[0])
        const f = acceptedFiles[0];

        if (f != null && f !== "undefined") {
            console.log(f);
            setFile(f)
            setFilePreview(URL.createObjectURL(f))

        }
        else {
            setFile(null)
            setFilePreview("File not supported")
        }

    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'image/jpg, image/jpeg,image/png,image/svg+xml,image/gif' })



    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'File',
                accessor: 'col2',
            },
            {
                Header: 'File URL',
                accessor: 'col3',
            },
            {
                Header: 'Action',
                accessor: 'col4',
            },
           
        ],
        [tabelData]
    )

    var data1 = React.useMemo(
        () => tabelData,
        [tabelData]
    )

    return (
        <div className="col  vh-100">
            <div className="container h-100">
                {!showForm && <div className="">
                    <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <h3 className="f-semibold m-0">Total No of Files</h3>
                            <h3 className="f-bold ps-5 m-0">{tabelData.length}</h3>
                        </div>
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <button className="adminThemebtn" onClick={(e) => {
                                setShowForm(true)
                                setFileId(null)

                                setFilePreview(null)
                                console.log(showForm)
                            }}>Add Files</button>
                        </div>
                    </div>
                    <div className="table-responsive adminTable ">
                        <DataTable columns={columns} data={data1} />
                    </div>

                </div>}
                {showForm && <div className="input-form col-12 col-lg-6 col-xxl-6 col-xl-6 col-md-8 col-sm-8 bg-white shadow-sm rounded p-4 m-auto mt-5">
                    <form>

                        <input type="hidden" value={fileId} />
                       
                      
                        <div className="col-12 col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 mb-4" {...getRootProps()}>
                            <label className="f-semibold mb-2 w-100">Upload Sticker</label>

                            <label htmlFor="upload-photo" className="IconUpload">
                                <img src={(filePreview == null || filePreview == " ") ? "../images/music.png" : filePreview} alt="Upload" /></label>
                            <input type="file" className="opacity-0" name="photo" id="upload-photo"  {...getInputProps({ className: 'dropzone' })} />

                        </div>
                        <div className="col-12 col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 pt-4 mb-4 d-flex justify-content-between">
                            <button className="adminThemebtn bg-light  text-dark" onClick={(e) => {
                                setShowForm(false)
                            }}>Cancel</button>
                            <button className="adminThemebtn" onClick={(e) => {
                                e.preventDefault();

                                if (file !== null) {
                                    
                                                   
                                        var formData = new FormData()
                                        formData.append("file", file)
                                        console.log(formData);
                                      

  var local = JSON.parse(localStorage.getItem("AdminInfo"))

  var t1 = local['token']

  const config = {
      headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${t1}`,
      },
  }
                                        axios.post(baseUrl+"api/web/uploadfilerequest", formData,config).then(res => {

                                            console.log(res.data)
                                            if (res.data.status == 1) {
                                                
                                                setShowForm(false)
                                                setdataLoad(!dataLoad)

                                            }
                                            else {
                                                setFilePreview("Failed to upload")
                                            }
                                        }).catch(err => {

                                            setFilePreview("Failed to upload")

                                        })
                                
                                    

                                }
                               

                                else {
                                    console.log("error");
                                    setFilePreview("fill the manditory field")
                                }

                            }}>Submit</button>

                        </div>

                    </form>
                </div>}
            </div>
            
        </div>

    )
}

export default ImportFiles
