import {

  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_CHECK_EMAIL_REQUEST,
  USER_CHECK_EMAIL_SUCCESS,
  USER_CHECK_EMAIL_FAIL



} from '../constants/UserConstant'

export const UserLoginReducer = (state = {}, action) => {

  switch (action.type) {
    case USER_LOGIN_REQUEST:

      return {
        loading: true
      }
    case USER_LOGIN_SUCCESS:
      return {
        loading: false, UserInfo: action.payload
      }
    case USER_LOGIN_FAIL:
      return {
        loading: false, error: action.payload
      }



    case USER_CHECK_EMAIL_REQUEST:

      return {
        emailcheckloading: true, error: "loading"
      }
    case USER_CHECK_EMAIL_SUCCESS:
      return {

        emailcheckloading: false, emailcheckerror: null
      }
    case USER_CHECK_EMAIL_FAIL:

      return {
        emailcheckloading: false, emailcheckerror: action.payload
      }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}