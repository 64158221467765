import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import UploadBredcumb from './UploadBredcumb'
import { Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
const MusicUpload = () => {
        const userLogin = useSelector((state) => state.userLogin)
        const { UserInfo } = userLogin
        const [file, setFile] = useState('')
        const [fileError, setFileError] = useState('')
        const [loading, setLoading] = useState(false)
        const [uploadSuccess, setUploadSuccess] = useState(false)

        useEffect(() => {
                setLoading(true)
                const formDatas = new FormData()
                formDatas.append("userId", UserInfo._id)

                const userId = UserInfo._id
                console.log(formDatas)
                var local = JSON.parse(localStorage.getItem("UserInfo"))

                var t1 = local['token']
        
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${t1}`,
                    },
                }
                axios.post(
                        baseUrl + 'api/web/getuserdata',
                        {
                                userId,
                        },
                        config
                ).then(res => {
                        console.log(res.data)
                        if (res.data.songDraft != null && res.data.songDraft.isDrafted) {
                                setUploadSuccess(true)
                                setFileError(res.data.songDraft.originalname)
                        }

                }).catch(err => {
                        console.log(err)
                })

                setLoading(false)

        }, [UserInfo]);

        const onDrop = useCallback(acceptedFiles => {
                // Do something with the files
                console.log(acceptedFiles[0])
                const f = acceptedFiles[0];
                  setFile(f);
                if (f != null&& f!=="undefined") {
                    
                        setLoading(true)
                        const formData = new FormData()
                        formData.append("userId", UserInfo._id)
                        formData.append("file", acceptedFiles[0])
                        console.log(formData)
                        var local = JSON.parse(localStorage.getItem("UserInfo"))

                        var t1 = local['token']
                
                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${t1}`,
                            },
                        }
                        axios.post(baseUrl + "api/web/musicupload", formData,config).then(res => {
                                setLoading(false)
                                console.log(res.data)
                                if (res.data.status == 1) {
                                        var filename = res.data.name
                                        console.log(res.data)
                                        setFileError(filename)
                                        setUploadSuccess(true)
                                }
                                else {
                                        var filename = res.data.message
                                        console.log(filename)
                                        setFileError(filename)
                                        setUploadSuccess(false)
                                }
                        }).catch(err => {
                                setLoading(false)
                                setFileError("Failed to upload")
                                setUploadSuccess(false)
                        })
                }
                else {
                        setFileError("File Not Supported")
                }
        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'audio/mpeg, audio/m4a' })
        return (
                <><UploadBredcumb activeStep={1} />
                        <div className="col p-0" >
                                <div className="container">
                                        <div className="text-center my-5 songUpload">
                                                <h1>Upload Song</h1>
                                                <div className="col songUploadInner py-4 "  {...getRootProps()}>
                                                        <h2>Upload an Vocal track. Make sure your file doesn't include vocals</h2>
                                                        <div className="upload-container text-center">
                                                                {!loading
                                                                        ? <div>
                                                                                <label htmlFor="upload-photo" className="songUpload"><img src="images/music.png" alt="Upload" /></label>
                                                                                <input /* type="file" name="photo" id="upload-photo"
                                                                                        */
                                                                                       accept=".mp3" 
                                                                                        {...getInputProps({ className: 'dropzone' })}
                                                                                       />
                                                                                <span className="error d-inline-block w-100 mt-3">{fileError}</span>

                                                                        </div>
                                                                        : <Spinner animation="grow" />
                                                                }
                                                                
                                                                        {
                                                                                isDragActive ?
                                                                                        <p>Drop the files here ...</p> :
                                                                                        <p></p>
                                                                        }

                                                              
                                                        </div>
                                                        <h3 className="mt-4">We support: m4a, mp3. Max file size: 20 MB. Max length: 10 mins.</h3>
                                                </div>
                                                {uploadSuccess && <div className=" pt-3 d-flex flex-row justify-content-end">
                                                        <Link to="/musicdetails" className="submitBtn "> Next </Link>
                                                </div>}
                                                <p className="mt-5"><sup>*</sup>  By uploading this song, you confirm that you are complying with all legal guidelines set forth by our Community Guidelines and Terms of Service.If you have any questions, please refer to our FAQ or contact YoYo Singer for more assistance.</p>
                                        </div>
                                </div>
                        </div>
                </>
        )
}

export default MusicUpload
