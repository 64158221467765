import React, { useRef, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useTable, usePagination } from 'react-table'
import axios from 'axios';
import { baseUrl } from '../../config/constant';
import useState from 'react-usestateref'
import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Spinner } from 'react-bootstrap'

const AdminGiftCoins = () => {
    const [userList, setUserList] = useState([])
    const [serverUrl, setServerUrl] = useState("")
    const [profilePicUrl, setProfilePicUrl] = useState("")
    const [coverPicUrl, setCoverPicUrl] = useState("")
    const [starSinger, setStarSinger] = useState(1000)
    const [dataLoad, setDataLoad] = useState(false)
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [optionsArray, setOptionsArray, useRefOptionsArray] = useState([])
    const [selectedUser, setSelectedUser, useRefSelectedUser] = useState([])
    const [selectedUserToken, setSelectedUserToken, useRefSelectedUserToken] = useState([])
    const [giftCoins, setGiftCoins, useRefGiftCoins] = useState(0)
    const [giftComment, setGiftComment, useRefGiftComment] = useState("")




    useEffect(() => {
        console.log("inside use effect")
        setIsLoading(true)
        var local = JSON.parse(localStorage.getItem("AdminInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        axios.post(
            baseUrl + 'api/web/getuserslist',
            {

            },
            config
        ).then(res => {
            if (res.data.userList != null) {
                setUserList(res.data.userList)
                setServerUrl(res.data.serverUrl)
                setCoverPicUrl(res.data.coverPicUrl)
                setProfilePicUrl(res.data.profilePicUrl)
                var data = [];


                userList.map((listValue, index) => {
                    data.push({
                        col1: index + 1,
                        col2: listValue.userName,
                        col3: listValue.gender,
                        col4: <img src={serverUrl + profilePicUrl + listValue.profilePic} width="80" />,
                        col5: listValue.email,
                        col6: <div class="normal-custom_check ">
                            <label class="control control--checkbox">
                                <input type="checkbox" checked={useRefSelectedUser.current.includes(listValue._id)} value={listValue._id} onChange={onSelectedUser} />
                                <div class="control__indicator"></div>
                            </label>
                        </div>,
                    });

                });
                axios.post(
                    baseUrl +'api/web/getadminsettings',
                    {

                    },
                    config
                ).then(res => {
                    if (res.data.adminSetting != null) {
                        console.log(res.data.adminSetting)
                        console.log(res.data)

                        setStarSinger(res.data.adminSetting.starSingerCount)


                    }

                }).catch(err => {
                    console.log(err)
                })

                console.log(data)
                setTableData(data)
                setDataLoad(true)

            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })

        axios.post(
            baseUrl +'api/web/getadminsettings',
            {

            },
            config
        ).then(res => {
            if (res.data.adminSetting != null) {
                console.log(res.data.adminSetting)
                console.log(res.data)

                setStarSinger(res.data.adminSetting.starSingerCount)


            }

        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })

    }, [dataLoad]);


    const columns = React.useMemo(
        () => [

            {
                Header: 'S.No',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'col2',
            },
            {
                Header: 'Gender',
                accessor: 'col3',
            },
            {
                Header: 'Photo',
                accessor: 'col4',
            },

            {
                Header: 'EmailID',
                accessor: 'col5',
            },
            {
                Header: 'Select',
                accessor: 'col6',
            },



        ],
        [tableData]
    )

    var data1 = React.useMemo(
        () => tableData,
        [tableData]
    )

    const onSelectedOptions = (e) => {
        if (optionsArray.includes(e.target.value)) {
            const selectedReasondSet = new Set([...optionsArray]);
            selectedReasondSet.delete(e.target.value)
            setOptionsArray([...selectedReasondSet]);
        }
        else {
            setOptionsArray([...optionsArray, e.target.value]);
        }



        var data = [];
        setSelectedUser([])

        userList.map((listValue, index) => {

            if (useRefOptionsArray.current.includes("all")) {
                setSelectedUser([...useRefSelectedUser.current, listValue._id])
            }
            if (useRefOptionsArray.current.includes("featured")) {
                if (listValue.featured) {
                    setSelectedUser([...useRefSelectedUser.current, listValue._id])
                }
            }
            if (useRefOptionsArray.current.includes("starsinger")) {
                if ((listValue.goldMedal + listValue.silverMedal + listValue.brozeMedal) > starSinger) {
                    setSelectedUser([...useRefSelectedUser.current, listValue._id])
                }
            }
            data.push({
                col1: index + 1,
                col2: listValue.userName,
                col3: listValue.gender,
                col4: <img src={serverUrl + profilePicUrl + listValue.profilePic} width="80" />,
                col5: listValue.email,
                col6: <div class="normal-custom_check ">
                    <label class="control control--checkbox">
                        <input type="checkbox" checked={useRefSelectedUser.current.includes(listValue._id)} value={listValue._id} onChange={onSelectedUser} />
                        <div class="control__indicator"></div>
                    </label>
                </div>,
            });



        });

        setTableData(data)

    };

    const onSelectedUser = (e) => {
        if (useRefSelectedUser.current.includes(e.target.value)) {
            const selectedReasondSet = new Set([...useRefSelectedUser.current]);
            selectedReasondSet.delete(e.target.value)
            setSelectedUser([...selectedReasondSet]);
        }
        else {
            setSelectedUser([...useRefSelectedUser.current, e.target.value]);
        }



        var data = [];


        userList.map((listValue, index) => {


            data.push({
                col1: index + 1,
                col2: listValue.userName,
                col3: listValue.gender,
                col4: <img src={serverUrl + profilePicUrl + listValue.profilePic} width="80" />,
                col5: listValue.email,
                col6: <div class="normal-custom_check ">
                    <label class="control control--checkbox">
                        <input type="checkbox" checked={useRefSelectedUser.current.includes(listValue._id)} value={listValue._id} onChange={onSelectedUser} />
                        <div class="control__indicator"></div>
                    </label>
                </div>,
            });



        });

        setTableData(data)

    };

    return (
        <div className="col  vh-100">
            <div className="container h-100">
                {true && <div className="">
                    <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                        <div className="componentsDetailsInner text-center d-flex align-items-center">
                            <h3 className="f-semibold m-0">Total No of Users</h3>
                            <h3 className="f-bold ps-5 m-0">{tableData.length}</h3>
                        </div>
                    </div>
                    <div className=" bg-white p-3 my-5 shadow-sm rounded">
                        <ul className='list-inline mt-4'>
                            <li className='list-inline-item col-lg-4 col-md-4 col-12 m-0'>
                                <div class="normal-custom_check ">
                                    <label class="control control--checkbox">
                                        <input type="checkbox" checked={optionsArray.includes("featured")} value="featured" onChange={onSelectedOptions} /><b>Featured</b>
                                        <div class="control__indicator"></div>
                                    </label>
                                </div>
                            </li>
                            <li className='list-inline-item col-lg-4 col-md-4 col-12 m-0'>
                                <div class="normal-custom_check ">
                                    <label class="control control--checkbox">
                                        <input type="checkbox" checked={optionsArray.includes("starsinger")} value="starsinger" onChange={onSelectedOptions} /> <b>Star Singer</b>
                                        <div class="control__indicator"></div>
                                    </label>
                                </div>
                            </li>
                            <li className='list-inline-item col-lg-4 col-md-4 col-12 m-0'>
                                <div class="normal-custom_check ">
                                    <label class="control control--checkbox">
                                        <input type="checkbox" checked={optionsArray.includes("all")} value="all" onChange={onSelectedOptions} /> <b>All</b>
                                        <div class="control__indicator"></div>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="table-responsive adminTable ">
                        {isLoading ?
                            <div className=" vh-100 d-flex w-100 align-items-center justify-content-center">
                                <Spinner animation="grow" />
                            </div>
                            :
                            <DataTable columns={columns} data={data1} />}
                        {useRefSelectedUser.current.length > 0 &&
                            <div className='giftcoinsButtonContainer col-12 col-lg-12 mb-5'>
                                <div className='row m-0'>
                                    <div className='col '>
                                        <h5 className='f-semibold'>No of Users : {useRefSelectedUser.current.length}</h5>

                                    </div>
                                    <div className='col'>

                                        <textarea className='form-control' value={useRefGiftComment.current} placeholder='Comment' onChange={(e) => {setGiftComment(e.target.value)}}></textarea>
                                    </div>
                                    <div className='col'>

                                        <input type='text' className='form-control'  value={useRefGiftCoins.current} placeholder='No of Coins'  onChange={(e) => {setGiftCoins(e.target.value)}}/>
                                    </div>
                                    <div className='col text-end'>
                                        <button className='btn btn-primary'
                                            onClick={(e) => {
                                                setIsLoading(true)
                                                setSelectedUserToken([])
                                                userList.map((listValue, index) => {
                                                 if(useRefSelectedUser.current.includes(listValue._id) && listValue.oneSignalTokenId !=null)
                                                        {
                                                            setSelectedUserToken([...useRefSelectedUserToken.current,listValue.oneSignalTokenId])
                                                        }
                                                });
                                               
                                                e.preventDefault();
                                                var local = JSON.parse(localStorage.getItem("AdminInfo"))

                                                var t1 = local['token']
                                        
                                                const config = {
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        "Authorization": `Bearer ${t1}`,
                                                    },
                                                }
                                        
                                                var tokens_array = useRefSelectedUserToken.current
                                                var selectedUsers = useRefSelectedUser.current
                                                var coins = useRefGiftCoins.current
                                                var comment = useRefGiftComment.current

                                              

                                                axios.post(baseUrl +"api/web/sendgiftcoins", { tokens_array, selectedUsers, coins,comment }, config).then(res => {


                                                    if (res.data.status == 1) {
                                                        setDataLoad(!dataLoad)
                                                        setIsLoading(false)
                                                        setGiftCoins(0)
                                                        setGiftComment("")
                                                        setSelectedUserToken([])
                                                        setSelectedUser([])
                                                        setOptionsArray([])
                                                    }
                                                    else {
                                                        setIsLoading(false)
                                                       
                                                    }
                                                }).catch(err => {
                                                    setIsLoading(false)
                                                    
                                                })
                                            }}
                                        >Gift coins</button>
                                    </div>

                                </div>
                            </div>}
                    </div>

                </div>}

            </div>

        </div>

    )
}

export default AdminGiftCoins
